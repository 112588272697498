import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BillingGroupAddress } from 'src/app/billing/models/billing-group/billing-group-address.model';
import { BillingGroup } from 'src/app/billing/models/billing-group/billing-group.model';

@Component({
  selector: 'app-update-address-modal',
  templateUrl: './update-address-modal.component.html',
  styleUrls: ['./update-address-modal.component.scss']
})
export class UpdateAddressModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<BillingGroupAddress>();

  form: UntypedFormGroup;
  billingGroup: BillingGroup

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup() {
    this.form = this.fb.group({
      companyName: [this.billingGroup?.companyName, Validators.required],
      streetWithNumber: [this.billingGroup?.addressLine1, Validators.required],
      postcodeAndCity: [this.billingGroup?.addressLine2, Validators.required],
      country: [this.billingGroup?.country, Validators.required]
    })
  }


  onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getAddressDetails());
      this.bsModalRef.hide();
    }
    else {
      this.toastr.error("Es liegen noch Validierungsfehler vor!");
      this.form.markAllAsTouched();
    }
  }

  getAddressDetails() {
    let updatedAdressData: BillingGroupAddress = {
      companyName: this.form.controls['companyName'].value,
      addressLine1: this.form.controls['streetWithNumber'].value,
      addressLine2: this.form.controls['postcodeAndCity'].value,
      country: this.form.controls['country'].value
    };
    return updatedAdressData;
  }

  public close() {
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field)!.invalid && this.form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }
}
