import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'app-ag-grid-action-buttons',
  templateUrl: './ag-grid-action-buttons.component.html',
  styleUrls: ['./ag-grid-action-buttons.component.scss']
})
export class AgGridActionButtonsComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh() {
    return false;
  }
}
