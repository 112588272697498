import { Component } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';

@Component({
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent extends BaseComponent {
  constructor() {
    super();
  }
}
