<div class="container">
    <div class="row">
     <div class="col-12 text-center">
       <h1>Access denied!</h1>
       <p>
        You don't have access to this app. <br>
        Please contact your supervisor for permission.
        </p>
     </div>
 </div>
</div>