import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AccountInfoModel } from './security/models/account-info.model';
import { AuthService } from './security/services/auth.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  collapsed: boolean = false;
  isIframe = false;
  userInformation: AccountInfoModel;
  private msalInstance: PublicClientApplication;
  private readonly _destroying$ = new Subject<void>();
  isIEOrEdgeLegacy: boolean;

  constructor(
    private router: Router,
    private msalAuthService: MsalService,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    if (this.isIEOrEdgeLegacy) {
      this.router.navigate(['unsupported-browser']);
    }
    this.isIframe = window !== window.parent && !window.opener;

    this.msalInstance = new PublicClientApplication(
      this.msalAuthService.instance.getConfiguration()
    );

    this.msalInstance.initialize()
      .then(() => {
        this.updateToken();
      })
      .catch(error => console.error('MSAL initialization error:', error));
  }

  toggleCollapse(collapsed: boolean) {
    this.collapsed = collapsed;
  }

  private updateToken() {
    this.broadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        if (this.msalInstance.getAllAccounts().length > 0) {
          const tokenRequest = this.getToken();
          this.msalInstance.acquireTokenSilent(tokenRequest)
            .then((request) => {
              this.authService.loginUserToBackend(request.idToken)
                .pipe(switchMap(() => this.authService.getMyAccount()))
                .subscribe(
                  res => { this.userInformation = res; },
                  error => {
                    localStorage.removeItem('app_token');
                    this.router.navigateByUrl('/unauthorized');
                  }
                );
            })
            .catch((error) => {
              if (localStorage.getItem('refreshed') === null) {
                localStorage['refreshed'] = true;
                window.location.reload();
              } else {
                localStorage.removeItem('refreshed');
                this.msalInstance.loginRedirect();
              }
            });
        }
      });
  }

  private getToken(): any {
    const accounts = this.msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      this.msalInstance.loginRedirect();
    }
    return { account: accounts[0], scopes: ['openid'] };
  }

  ngOnDestroy(): void {
    localStorage.clear();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
