import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BillingGroupNameStatus } from 'src/app/billing/models/billing-group/billing-group-name-status.model';
import { BillingGroup } from 'src/app/billing/models/billing-group/billing-group.model';

@Component({
  selector: 'app-update-name-and-status-modal',
  templateUrl: './update-name-and-status-modal.component.html',
  styleUrls: ['./update-name-and-status-modal.component.scss']
})
export class UpdateNameAndStatusModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<BillingGroupNameStatus>();
  @ViewChild('isActiveSwitch') isActiveSwitch: ElementRef;

  form: UntypedFormGroup;
  billingGroup: BillingGroup
  tempActiveState: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private httpClient: HttpClient,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.tempActiveState = this.billingGroup.isActive;
    this.form.controls['isActive'].valueChanges.subscribe(x => this.tempActiveState = !this.tempActiveState)
  }

  initFormGroup() {
    this.form = this.fb.group({
      billingGroupName: [this.billingGroup?.name, Validators.required],
      isActive: [this.billingGroup?.isActive, Validators.required],
    })
  }


  onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getAddressDetails());
      this.bsModalRef.hide();
    }
    else {
      this.toastr.error("Es liegen noch Validierungsfehler vor!");
      this.form.markAllAsTouched();
    }
  }

  getAddressDetails() {
    let updatedNameStatus: BillingGroupNameStatus = {
      name: this.form.controls['billingGroupName'].value,
      isActive: this.form.controls['isActive'].value,

    };
    return updatedNameStatus;
  }

  public close() {
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field)!.invalid && this.form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }

}
