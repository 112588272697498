import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectUpdate } from "../models/project/project-update.model";
import { Project } from "../models/project/project.model";

@Injectable({
    providedIn: 'root'
})

export class ProjectService {

    constructor(private http: HttpClient) { }

    // get a list of projects(active) between two dates 
    public getProjects(startDateAsString: string, endDateAsString: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${environment.api}/project/activeByDateRange?startDateAsString=${startDateAsString}&endDateAsString=${endDateAsString}`);
    }
    
    // check if a list of projects(active) between two dates have at least a item incomplete
    public IsAnyIncompleteProject(startDateAsString: string, endDateAsString: string): Observable<boolean> {
        return this.http.get<boolean>(`${environment.api}/project/haveIncompleteProjectsByDateRange?startDateAsString=${startDateAsString}&endDateAsString=${endDateAsString}`);
    }

    public getProjectById(id: string): Observable<Project> {
        return this.http.get<Project>(`${environment.api}/project/${id}`);
    }

    public getBillingGroupProjects(id: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${environment.api}/project/billingGroupProjects/${id}`);
    }

    public updateProject(id: string, projectUpdate: ProjectUpdate): Observable<ProjectUpdate> {
        return this.http.put<ProjectUpdate>(`${environment.api}/project/${id}`, projectUpdate);
    }

    public updateActivityProject(id: string, stateActivity: boolean): Observable<ProjectUpdate> {

        if(stateActivity){
            return this.http.put<ProjectUpdate>(`${environment.api}/project/${id}/active`, stateActivity);
        }

        return this.http.put<ProjectUpdate>(`${environment.api}/project/${id}/inactive`, stateActivity);  
        
    }
}