import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { CustomerDetailsComponent } from './components/customer/customer-details/customer-details.component';
import { CustomerOverviewComponent } from './components/customer/overview/customer-overview.component';
import { ProjectDetailsComponent } from './components/customer/project-details/project-details.component';
import { ImportOverviewComponent } from './components/import/import-overview.component';
import { InvoiceDetailsComponent } from './components/invoice/invoice-detail/invoice-details.component';
import { InvoiceOverviewComponent } from './components/invoice/overview/invoice-overview.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ProjectOverviewComponent } from './components/project/overview/project-overview.component';
import { WorktimeOverviewComponent } from './components/worktime/overview/worktime-overview.component';
import { BillingGroupOverviewComponent } from './components/billing-group/billing-group-overview/billing-group-overview.component';
import { BillingGroupDetailsComponent } from './components/billing-group/billing-group-details/billing-group-details.component';
import { BillingGroupCreateComponent } from './components/billing-group/billing-group-create/billing-group-create.component';
import { environment } from 'src/environments/environment';

import { EmployeeOverviewComponent } from './components/employee/employee-overview.component';
import { InvoicePreviewComponent } from './components/invoice/preview/invoice-preview.component';
import { InvoiceSummaryComponent } from './components/invoice/summary/invoice-summary.component';

const routes: Routes = [
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: 'overview', component: OverviewComponent },
  { path: 'customers', component: CustomerOverviewComponent },
  { path: 'customer/:id', component: CustomerDetailsComponent },
  { path: 'worktime', component: WorktimeOverviewComponent },
  { path: 'projects', component: ProjectOverviewComponent },
  {
    path: 'customer/:customerId/:projectId',
    component: ProjectDetailsComponent,
  },
  { path: 'employees', component: EmployeeOverviewComponent },
  //{ path: 'employee/:id', component: EmployeeDetailsComponent },
  { path: 'invoices', component: InvoiceOverviewComponent },
  { path: 'invoices/create', component: InvoiceOverviewComponent },
  { path: 'invoices/:id', component: InvoiceDetailsComponent },
  { path: 'invoices-summary', component: InvoiceSummaryComponent },
  { path: 'invoices-preview', component: InvoicePreviewComponent },
  { path: 'billing-group', component: BillingGroupOverviewComponent },
  { path: 'billing-group/create', component: BillingGroupCreateComponent },
  { path: 'billing-group/:id', component: BillingGroupDetailsComponent },
  { path: 'import', component: ImportOverviewComponent },
  {
    path: 'hangfire', component: ImportOverviewComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: environment.hangfire
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }
  ]
})
export class BillingRoutingModule {
  constructor() { }
}
