import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Employee } from 'src/app/billing/models/employee/employee.model';
import { EmployeeService } from 'src/app/billing/services/employee.service';
import { EditCustomerDetailsModalComponent } from '../../customer/customer-details/edit-customer-details-modal/edit-customer-details-modal.component';
import { EditEmployeeDetailsModalComponent } from '../edit-employee-details-modal/edit-employee-details-modal.component';

@Component({
  selector: 'app-ag-grid-employee-action-buttons',
  templateUrl: './ag-grid-employee-action-buttons.component.html',
  styleUrls: ['./ag-grid-employee-action-buttons.component.scss']
})
export class AgGridEmployeeActionButtonsComponent 
implements ICellRendererAngularComp {
  bsModalRef?: BsModalRef;
  params: ICellRendererParams;
  value: string;
  gridApi: GridApi;
  gridData: any;
  employee: Employee;

  constructor(
    private router: Router,
		private modalService: BsModalService,
		private toastr: ToastrService, 
    private employeeService: EmployeeService
  ) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.gridApi = params.api;

    this.employeeService.getEmployeeById(params.data.id).subscribe((x: any) => {
      this.employee = x;
    });
  }

  refresh() {
    return false;
  }

  updateEmployee() {
		const initialState: ModalOptions = {
			backdrop: 'static',
			class: 'modal-lg',
			keyboard: false,
			initialState: {
				employee: this.employee
			},
		};
		const bsModalRef = this.modalService.show(EditEmployeeDetailsModalComponent, initialState);

		bsModalRef.content?.confirmed.pipe(take(1))
			.subscribe(x => {
        this.loadProjects();
			})
	}

  loadProjects() {
		this.employeeService.getEmployees().subscribe(() => {
			this.refresh();
		});
	}

}
