import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridActionButtonsComponent } from './ag-grid-action-buttons/ag-grid-action-buttons.component';

@Injectable()
export class BillingGroupGridColumnService {
  constructor() { }

  async getColumns(): Promise<(ColDef | ColGroupDef)[]> {
    const cols: ColDef[] = [
      {
        field: 'name',
        headerName: 'Billing group name',
        minWidth: 150
      },
      {
        field: 'billingAddress',
        headerName: 'Billing address',
        minWidth: 300
      },
      {
        field: 'projects',
        headerName: 'Projects',
        minWidth: 150
      },
      {
        field: 'customerOrderNumber',
        headerName: 'Customer order No.',
        width: 185,
      },
      {
        field: 'bank.bank',
        headerName: 'Bank name',
        width: 260,
      },
      {
        field: 'language',
        headerName: 'Lang',
        width: 100,
      },
      {
        field: 'isActive',
        width: 110,
        suppressMenu: true,
        editable: false,
        cellRenderer: params => { 
          if(params.value){
            return `<i class="fas fa-check" style="margin:0 30% 0 30%"></i>`;
          }
          else{
            return `<i class="fas fa-ban" style="margin:0 30% 0 30%"></i>`;
          }
        }
      },
      {
        field: '',
        cellRenderer: AgGridActionButtonsComponent,
        cellEditorPopupPosition: 'under',
        minWidth: 68,
        maxWidth: 68,
        filter: false,
        pinned: 'right'
      },
    ];

    return cols;
  }
}
