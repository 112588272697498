<div class="container-fluid mt-3">

    <div class="card-header mb-4">
        <div class="form-group">
            <a [routerLink]="['/customer/'+ customerId]">
                <button class="btn btn-primary">
                    <i class="fas fa-arrow-left">
                    </i>
                    Go back
                </button>
            </a>
        </div>
    </div>

    <div class="d-flex pb-2 ">
        <h2 class="pe-3"><span *ngIf="projectLoaded()">{{project.name}}</span></h2>
    </div>

    <div *ngIf="projectLoaded()">
        <div>
            <label>Status</label>
            <p [ngClass]="project.isActive ? 'text-success' : 'text-danger'">{{ project.isActive ? 'Active' : 'Inactive'
                }}
            </p>
        </div>
        <form class="projectForm">
            <div class="form-group col">
                <label>Select a billing group</label>
                <select class="form-select" name="billingGroup"
                    (change)='selectedBillingGroupChanged($event.target.value)' [(ngModel)]="project.billingGroupId">
                    <option *ngFor=" let billingGroup of billingGroups" [value]="billingGroup.id">{{billingGroup.name}}
                    </option>
                </select>
            </div>
            <div class="form-group col">
                <label for="projectReferenceInput">Project reference</label>
                <input type="text" class="form-control" id="projectReferenceInput" placeholder="Project reference"
                    (change)='project.projectReference=$event.target.value' [value]="project.projectReference">
            </div>

            <div class="form-group col hourlyRateControl">
                <label for="hourlyRateInput">Hourly rate</label>
                <input type="number" class="form-control" id="hourlyRateInput" placeholder="Enter hourly rate"
                    (change)='project.hourlyRate=$event.target.value' [value]="project.hourlyRate">
            </div>

            <button type="button" class="btn btn-primary mt-4" (click)="updateProject()">Update project</button>
        </form>
    </div>
</div>

<!-- TODO: Implement Employees -->
<!-- <div>
    <div class="d-flex mt-3 pb-2 ">
        <h3 class="pe-3" [ngClass]="{'text-secondary' : employees && !employees}"> Employees
        </h3>
    </div>
    <ng-container *ngIf="employees">
        <ul class="list-group" *ngIf="employees">
            <li *ngFor="let employee of employees" class="list-group-item">{{employee.name}}</li>
        </ul>
        <li *ngIf="!employees" class="list-group-item text-secondary">No employees found</li>
    </ng-container>

    <ng-container *ngIf="!employees">
        <p class="text-left">Loading employees... <span class="spinner-grow spinner-grow-sm ml-3" role="status"
                aria-hidden="true"></span></p>
    </ng-container>
</div> -->