import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTwoDecimalNumber]'
})

export class TwoDecimalNumberDirective {
  @Input("decimals") decimals:number = 2;

  private check(value: string) {
      if (this.decimals <= 0) {
          return String(value).match(new RegExp(/^\d+$/));
      } else {
          var regExpString =
              "^\\s*((\\d+(\\.\\d{0," +
              this.decimals +
              "})?)|((\\d*(\\.\\d{1," +
              this.decimals +
              "}))))\\s*$";
          var resp = String(value).match(new RegExp(regExpString))
          return resp

      }
  }

  private run(oldValue) {
      setTimeout(() => {
          let currentValue: string = this.el.nativeElement.value;
          if (currentValue !== '' && !this.check(currentValue)) {
              this.el.nativeElement.value = oldValue;
          }
      });
  }

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
      this.run(this.el.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
      this.run(this.el.nativeElement.value);
  }

}