import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BillingGroupAddress } from "../models/billing-group/billing-group-address.model";
import { BillingGroupCreate } from "../models/billing-group/billing-group-create.model";
import { BillingGroupEmailSetting } from "../models/billing-group/billing-group-email-settings.model";
import { BillingGroupInvoiceInformation } from "../models/billing-group/billing-group-invoice-information.model";
import { BillingGroupNameStatus } from "../models/billing-group/billing-group-name-status.model";
import { BillingGroup } from "../models/billing-group/billing-group.model";
import { Project } from "../models/project/project.model";

@Injectable({
    providedIn: 'root'
})

export class BillingGroupService {

    constructor(private http: HttpClient) { }

    public getAllBillingGroups(): Observable<BillingGroup[]> {
        return this.http.get<BillingGroup[]>(`${environment.api}/billingGroup`);
    }

    public getAllActiveBillingGroups(): Observable<BillingGroup[]> {
        return this.http.get<BillingGroup[]>(`${environment.api}/billingGroup/active`);
    }

    public getActiveNotInternalBillingGroups(): Observable<BillingGroup[]> {
        return this.http.get<BillingGroup[]>(`${environment.api}/billingGroup/activeNotInternal`);
    }

    public getBillingGroupById(id: string): Observable<BillingGroup> {
        return this.http.get<BillingGroup>(`${environment.api}/billingGroup/${id}`);
    }

    public GetBillingGroupProjectsWorktimes(id: string, startDate: Date, endDate: Date): Observable<Project[]> {
        return this.http.get<Project[]>(`${environment.api}/billingGroup/${id}/projectworktimes?startDate=${startDate.toUTCString()}&endDate=${endDate.toUTCString()}`);
    }

    public updateBillingGroupAddress(id: string, billingGroupAddress: BillingGroupAddress): Observable<BillingGroupAddress> {
        return this.http.put<BillingGroupAddress>(`${environment.api}/billingGroup/${id}/address`, billingGroupAddress);
    }

    public updateBillingGroupInvoiceInformation(id: string, billingGroupInvoiceInformation: BillingGroupInvoiceInformation): Observable<BillingGroupInvoiceInformation> {
        return this.http.put<BillingGroupInvoiceInformation>(`${environment.api}/billingGroup/${id}/invoiceInformation`, billingGroupInvoiceInformation);
    }

    public updateBillingGroupNameAndStatus(id: string, billingGroupNameAndStatus: BillingGroupNameStatus): Observable<BillingGroupNameStatus> {
        return this.http.put<BillingGroupNameStatus>(`${environment.api}/billingGroup/${id}/nameAndStatus`, billingGroupNameAndStatus);
    }

    public updateBillingGroupEmailSettings(id: string, billingGroupEmailSettings: BillingGroupEmailSetting): Observable<BillingGroupEmailSetting> {
        return this.http.put<BillingGroupEmailSetting>(`${environment.api}/billingGroup/${id}/emailSettings`, billingGroupEmailSettings);
    }

    public createBillingGroup(createdBillingGroup: BillingGroupCreate): Observable<string> {
        return this.http.post<string>(`${environment.api}/billingGroup`, createdBillingGroup);
    }

    public deleteBillingGroup(id: string): Observable<BillingGroup> {
        return this.http.delete<BillingGroup>(`${environment.api}/billingGroup/${id}`);
    }
}