import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthErrorComponent } from './components/auth-error/auth-error.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RouterModule } from '@angular/router';

export const protectedResourceMap: any = [
  [environment.api, environment.msal.scopeUri]
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    AuthErrorComponent,
    LogoutComponent
  ]
})
export class SecurityModule {
  constructor(@Optional() @SkipSelf() parentModule: SecurityModule) {
    if (parentModule) {
      throw new Error(
        'SecurityModule is already loaded. Import it in the AppModule only');
    }
  }
}
