<div class="modal-header" *ngIf="title">
  <h4 class="modal-title pull-left">
    {{title}}
  </h4>
</div>
  <div class="modal-body">
  <div class="spinner-border mx-auto" style="width: 3rem; height: 3rem; display: block;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
