import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "@premotec/ngx-essentials";
import { WorktimeService } from "src/app/billing/services/worktime.service";


@Component({
    templateUrl: './worktime-overview.component.html',
    styleUrls: ['./worktime-overview.component.scss']
})
export class WorktimeOverviewComponent extends BaseComponent implements OnInit {

    public loading: boolean;
    public importing: boolean;

    constructor(
        private worktimeService: WorktimeService,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
    }

    importWorktime() {
        this.loading = true;
        this.worktimeService.triggerWorktimeImport().subscribe(x => {
            this.loading = false;
        });
    }
}