import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AgGridModule } from 'ag-grid-angular';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { AgGridSharedModule } from '../shared/ag-grid-shared/ag-grid-shared.module';
import { BillingRoutingModule } from './billing-routing.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CustomerDetailsComponent } from './components/customer/customer-details/customer-details.component';
import { CustomerOverviewComponent } from './components/customer/overview/customer-overview.component';
import { CustomerGridColumnService } from './components/customer/overview/customer-grid-column.service';
import { ProjectDetailsComponent } from './components/customer/project-details/project-details.component';
import { ImportOverviewComponent } from './components/import/import-overview.component';
import { CreateOrUpdatePositionModalComponent } from './components/invoice/invoice-detail/createOrUpdate-position-modal/createOrUpdate-position-modal.component';
import { EditInvoiceModalComponent } from './components/invoice/invoice-detail/edit-invoice-modal/edit-invoice-modal.component';
import { InvoiceDetailsComponent } from './components/invoice/invoice-detail/invoice-details.component';
import { AgGridEditButtonComponent } from './components/invoice/overview/ag-grid-edit-button/ag-grid-edit-button.component';
import { InvoiceOverviewColumnService } from './components/invoice/overview/invoice-overview-grid-column.service';
import { OverviewComponent } from './components/overview/overview.component';
import { WorktimeOverviewComponent } from './components/worktime/overview/worktime-overview.component';
import { BankService } from './services/bank.service';
import { BillingGroupService } from './services/billing-group.service';
import { CustomerService } from './services/customer.service';
import { EmployeeService } from './services/employee.service';
import { InvoiceService } from './services/invoice.service';
import { ProjectService } from './services/project.service';
import { WorktimeService } from './services/worktime.service';
import { AgGridCustomerActionButtonsComponent } from './components/customer/overview/ag-grid-customer-action-buttons/ag-grid-customer-action-buttons.component';
import { AgGridEmployeeActionButtonsComponent } from './components/employee/ag-grid-employee-action-buttons/ag-grid-employee-action-buttons.component';
import { EditCustomerDetailsModalComponent } from './components/customer/customer-details/edit-customer-details-modal/edit-customer-details-modal.component';
import { EditEmployeeDetailsModalComponent } from './components/employee/edit-employee-details-modal/edit-employee-details-modal.component';
import { CreateInvoiceModalComponent } from './components/invoice/overview/create-invoice-modal/create-invoice-modal.component';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { ActiveProjectPipe } from '../shared/pipes/active-project.pipe';
import { SharedModule } from '../shared/shared.module';
import { CurrencyCustomPipe } from '../shared/pipes/currencyCustomPipe.pipe';
import { InvoiceOverviewComponent } from './components/invoice/overview/invoice-overview.component';
import { ProjectOverviewComponent } from './components/project/overview/project-overview.component';
import { CompleteProjectPipe } from '../shared/pipes/complete-project.pipe';
import { AgGridActionButtonsComponent } from './components/billing-group/billing-group-overview/ag-grid-action-buttons/ag-grid-action-buttons.component';
import { BillingGroupGridColumnService } from './components/billing-group/billing-group-overview/billing-group-grid-column.service';
import { BillingGroupOverviewComponent } from './components/billing-group/billing-group-overview/billing-group-overview.component';
import { BillingGroupDetailsComponent } from './components/billing-group/billing-group-details/billing-group-details.component';
import { SafePipe } from '../shared/pipes/safe.pipe';
import { InvoiceInformationComponent } from './components/billing-group/billing-group-details/invoice-information/invoice-information.component';
import { UpdateInvoiceInformationModalComponent } from './components/billing-group/billing-group-details/invoice-information/update-invoice-information-modal/update-invoice-information-modal.component';
import { UpdateAddressModalComponent } from './components/billing-group/billing-group-details/billing-group-address/update-billing-group-address-modal/update-address-modal.component';
import { AddressComponent } from './components/billing-group/billing-group-details/billing-group-address/address.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdateNameAndStatusModalComponent } from './components/billing-group/billing-group-details/update-name-and-status-modal/update-name-and-status-modal.component';
import { EmailSettingsComponent } from './components/billing-group/billing-group-details/email-settings/email-settings.component';
import { UpdateEmailSettingsModalComponent } from './components/billing-group/billing-group-details/email-settings/update-email-settings-modal/update-email-settings-modal.component';
import { BillingGroupCreateComponent } from './components/billing-group/billing-group-create/billing-group-create.component';
import { EmployeeOverviewComponent } from './components/employee/employee-overview.component';
import { InvoicePreviewComponent } from './components/invoice/preview/invoice-preview.component';
import { CreateInvoicePreviewModalComponent } from './components/invoice/preview/create-invoice-preview-modal/create-invoice-preview-modal.component';
import { PmtDatepickerModule } from '@premotec/pmt-datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceSummaryComponent } from './components/invoice/summary/invoice-summary.component';
import { AgGridInfoButtonComponent } from './components/invoice/overview/ag-grid-info-button/ag-grid-info-button.component';


@NgModule({
  imports: [
    CommonModule,
    BillingRoutingModule,
    DragDropModule,
    SharedModule,
    DragDropModule,
    TranslocoRootModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    PmtDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    AgGridSharedModule,
    NgSelectModule
  ],
  declarations: [
    SafePipe,
    OverviewComponent,
    CustomerOverviewComponent,
    CustomerDetailsComponent,
    WorktimeOverviewComponent,
    ProjectDetailsComponent,
    InvoiceOverviewComponent,
    InvoicePreviewComponent,
    InvoiceDetailsComponent,
    BillingGroupOverviewComponent,
    ImportOverviewComponent,
    AgGridActionButtonsComponent,
    AgGridEditButtonComponent,
    AgGridInfoButtonComponent,
    CreateOrUpdatePositionModalComponent,
    EditInvoiceModalComponent,
    CurrencyCustomPipe,
    ActiveProjectPipe,
    CompleteProjectPipe,
    AgGridCustomerActionButtonsComponent,
    AgGridEmployeeActionButtonsComponent,
    EditCustomerDetailsModalComponent,
    EditEmployeeDetailsModalComponent,
    CreateInvoiceModalComponent,
    CreateInvoicePreviewModalComponent,
    ProjectOverviewComponent,
    BillingGroupDetailsComponent,
    UpdateAddressModalComponent,
    AddressComponent,
    InvoiceInformationComponent,
    UpdateInvoiceInformationModalComponent,
    UpdateNameAndStatusModalComponent,
    EmailSettingsComponent,
    UpdateEmailSettingsModalComponent,
    BillingGroupCreateComponent,
    EmployeeOverviewComponent,
    InvoiceSummaryComponent
  ],
  providers: [
    CustomerService,
    CustomerGridColumnService,
    WorktimeService,
    ProjectService,
    EmployeeService,
    InvoiceService,
    BillingGroupService,
    BillingGroupGridColumnService,
    InvoiceOverviewColumnService,
    BankService,
    ModalModule,
    AgGridModule,
    ReactiveFormsModule,
    ToastrModule,
  ],
})

export class BillingModule { }
