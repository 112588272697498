<div class="text-center">
  <h4 class="mt-3">
    <i class="fa-2x text-{{color}}" [ngClass]="icon ? icon : 'fas fa-circle-question'"></i>
  </h4>

  <div class="my-3">
    <h5 *ngIf="title">{{title}}</h5>
    <div [innerHtml]="body"></div>
  </div>
  <div class="mb-3">
    <div class="btn btn-light border me-1" *ngIf="!onlyConfirm" (click)="decline()">{{'confirmationModal.cancel' | transloco}}</div>
    <div class="btn btn-{{color}} me-1" (click)="confirm()">OK</div>
  </div>
</div>