import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EmployeeProject } from 'src/app/billing/models/employee/employee-project.model';
import { Project } from 'src/app/billing/models/project/project.model';
import { EmployeeService } from 'src/app/billing/services/employee.service';
import { Employee } from 'src/app/billing/models/employee/employee.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-edit-employee-details-modal',
  templateUrl: 'edit-employee-details-modal.component.html',
  styleUrls: ['edit-employee-details-modal.component.scss']
})
export class EditEmployeeDetailsModalComponent extends BaseComponent implements OnInit {

  @Output() confirmed = new EventEmitter<boolean>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;
  modalRef?: BsModalRef;
  form = new UntypedFormGroup({});
  projectEmployeeForm = new UntypedFormGroup({});
  employee: Employee;
  message = new UntypedFormControl('', Validators.required);
  submitted = false;
  prEmployees: EmployeeProject[];

  constructor(
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public toastr: ToastrService,
    private employeeService: EmployeeService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.loadEmployees();
    this.initFormGroup();
  }



  loadEmployees() {
    this.employeeService.getEmployeeEntities(this.employee.id).subscribe(x => {
      this.prEmployees = x;
      this.prEmployees.forEach(element => {
        this.initProjectEmployeeForm(element.id, element.hourlyRate, element.customer.name, element.project.name);
      });
    });
  }

  initFormGroup() {
    this.form = this.fb.group({
      id: [this.employee?.id, Validators.required],
      projectEmployees: this.fb.array([])
    });
  }

  initProjectEmployeeForm(id:string, hourlyRate: number, customerName: string, projectName: string)
  {
    const projectEmployeeForm = this.fb.group({
      id:[id],
      employeeId: [this.employee?.id, Validators.required],
      hourlyRate: [hourlyRate],
      customerName: [customerName],
      projectName: [projectName]
    });

    this.projectEmployees.push(projectEmployeeForm);
  }
  
  onSubmit() {
    let employeeData: Employee = {... this.form.value}
    let id = this.form.get('id').value;
    if (this.form.valid) {
      this.employeeService.updateEmployee(String(id), employeeData).subscribe(x=> {
        this.toastr.success('Employee successfully updated!');
        this.confirmed.emit(true);
        this.bsModalRef.hide();
      })
    }
    else {
      this.toastr.error('This form has validation errors!', '');
    }
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return 'is-invalid'
    }
    return '';
  }

  get hourlyRate(){
    return this.form.get('hourlyRate');
  }

  get projectEmployees(){
    return this.form.controls['projectEmployees'] as UntypedFormArray;
  }

}
