import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { BillingGroupEmailSetting } from 'src/app/billing/models/billing-group/billing-group-email-settings.model';
import { BillingGroup } from 'src/app/billing/models/billing-group/billing-group.model';
import { BillingGroupService } from 'src/app/billing/services/billing-group.service';
import { UpdateEmailSettingsModalComponent } from './update-email-settings-modal/update-email-settings-modal.component';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {

  @Input() billingGroup: BillingGroup;

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private billingGroupService: BillingGroupService) { }

  ngOnInit(): void {
  }

  onEditEmailSettings() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        billingGroup: this.billingGroup
      },
    };
    const bsModalRef = this.modalService.show(UpdateEmailSettingsModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe((updatedEmailSettings: BillingGroupEmailSetting) => {
        this.billingGroupService.updateBillingGroupEmailSettings(this.billingGroup.id, updatedEmailSettings).subscribe(
          x => {
            this.billingGroup.emailSalutation = updatedEmailSettings.salutation;
            this.billingGroup.emailRecipients = updatedEmailSettings.recipients;
            this.toastr.success("Data successfully updated!")
          },
          err => {
            this.toastr.error(err.error, "An error occured!")
          })
      })
  }

}
