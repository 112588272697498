import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Customer } from "../models/customer/customer.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Project } from "../models/project/project.model";
import { Employee } from "../models/employee/employee.model";

@Injectable({
    providedIn: 'root'
})
export class WorktimeService {

    constructor(private http: HttpClient) { }

    public triggerWorktimeImport(): Observable<boolean> {
        return this.http.get<boolean>(`${environment.api}/worktime/import`);
    }
}