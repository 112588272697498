import { GridOptions } from 'ag-grid-community';

export function getDefaultGridOptions(init?: Partial<GridOptions>): GridOptions {
  const options = <GridOptions>Object.assign(<GridOptions>{}, <GridOptions>{
    suppressPropertyNamesCheck: true,
    rowSelection: 'single',
    defaultColDef: {
      resizable: true,
      sortable: true,
      floatingFilter: true,
    },
  }, init);

  return options;
}
