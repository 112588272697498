<div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark custom-sidebar" [ngClass]="collapsed ? 'sidebar-collapsed-width' : 'sidebar-width'" pmtAutoWindowHeight>
    <ul class="nav nav-pills flex-column mb-auto">

      <!-- Billing Group / Customer -->
      <div [ngClass]="{'mb-3' : !textCollapsed}">
        <li *ngIf="!textCollapsed" class="mb-1">
          <a class="text-light h5 fw-normal">{{ 'BasicData' | transloco }}</a>
        </li>
        <app-link-item
          routerLinkInput="billing/billing-group"
          translocoText="BillingGroups"
          iconCode="fas fa-users"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
        <app-link-item
          routerLinkInput="billing/customers"
          translocoText="Customers"
          iconCode="fas fa-building-user"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
        <app-link-item
          routerLinkInput="billing/projects"
          translocoText="Projects"
          iconCode="fas fa-folder-open"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
        <app-link-item
        routerLinkInput="billing/employees"
        translocoText="Employees"
        iconCode="fa-solid fa-users"
        [collapsed]="collapsed"
        [textCollapsed]="textCollapsed">
      </app-link-item>
      </div>

      <!-- Invoice -->
      <div [ngClass]="{'mb-3' : !textCollapsed}">
        <li *ngIf="!textCollapsed" class="mb-1">
          <a class="text-light h5 fw-normal">{{ 'invoice' | transloco }}</a>
        </li>
        <app-link-item
          routerLinkInput="billing/invoices"
          [translocoText]="textCollapsed ? 'InvoiceDropdown.InvoiceHistory' : 'Overview'"
          iconCode="fas fa-file-invoice-dollar"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
        <app-link-item
          routerLinkInput="billing/invoices/create"
          [translocoText]="textCollapsed ? 'InvoiceDropdown.CreateInvoice' : 'Create'"
          iconCode="fas fa-file-circle-plus"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
        <app-link-item
          routerLinkInput="billing/invoices-summary"
          [translocoText]="textCollapsed ? 'InvoiceDropdown.Summary' : 'Summary'"
          iconCode="fas fa-list-alt"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
        <app-link-item
          routerLinkInput="billing/invoices-preview"
          [translocoText]="textCollapsed ? 'InvoiceDropdown.Preview' : 'Preview'"
          iconCode="fas fa-eye"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
      </div>

      <!-- Data Transfer -->
      <div [ngClass]="{'mb-3' : !textCollapsed}">
        <li *ngIf="!textCollapsed" class="mb-1">
          <a class="text-light h5 fw-normal">{{ 'Services' | transloco }}</a>
        </li>
        <app-link-item
          routerLinkInput="billing/import"
          translocoText="Import"
          iconCode="fas fa-file-import"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
        <app-link-item
          routerLinkInput="billing/hangfire"
          translocoText="Hangfire"
          iconCode="fas fa-clock"
          [collapsed]="collapsed"
          [textCollapsed]="textCollapsed">
        </app-link-item>
      </div>
    </ul>
  
    <div [ngClass]="!collapsed? 'custom-position' : 'custom-position-collapsed'">
      <hr>
      <a (click)="toggleNavbar()" class="cursor-pointer text-white text-center">
        <div *ngIf="!collapsed">
          <i class="fas fa-chevron-left custom-margin-right"></i>
          <i class="fas fa-chevron-left"></i>
          <span *ngIf="!textCollapsed"> Einklappen</span>
        </div>
        <div *ngIf="collapsed">
          <i class="fas fa-chevron-right custom-margin-right"></i>
          <i class="fas fa-chevron-right"></i>
        </div>
      </a>
  </div>