<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Create Invoice</h4>
</div>
<div class="modal-body">
    <div class="invoiceContainer">
        <div class="row">
            <div class="col">
                <label for="startDate">Start date</label>
                <pmt-datepicker
                    [(ngModel)]="startDate"
                    [id]="'startDate'">
                </pmt-datepicker>
            </div>
            <div class="col">
                <label for="endDate">End date</label>
                <pmt-datepicker
                    [(ngModel)]="endDate"
                    (ngModelChange)="checkIfProjectsAreIncomplete()"
                    [id]="'endDate'">
                </pmt-datepicker>
            </div> 
        </div>

        <div class="row">
            <div class="col">
                <button class="btn btn-light border me-2 btn-sm" (click)="setLastMonthDates()">Last month</button>
                <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentMonthDates()">Current month</button>
                <button class="btn btn-light border me-2 btn-sm" (click)="setLastQuarterDates()">Q{{this.lastQuarter + 1}} {{this.lastQuarterYear}}</button>
                <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentQuarterDates()">Q{{this.currentQuarter + 1}} {{this.currentQuarterYear}}</button>
                <button class="btn btn-light border me-2 btn-sm" (click)="setNextQuarterDates()">Q{{this.nextQuarter + 1}} {{this.nextQuarterYear}}</button>
            </div>
        </div>

        <!--button type="button" class="btn btn-primary mt-2" (click)="createInvoiceFromWorktime()">Create invoice</button-->
        <div *ngIf="rangeWithIncompleteProjects" class="row mt-3">
            <div class="col text-center">
                There are incomplete projects for this time-period.<br>
                Click <a [routerLink]="['/billing/projects']" [queryParams]="{startdate : getShortDate(startDate), enddate : getShortDate(endDate), incompleteprojects : true }" (click)="close()">here</a>  to fix these projects.
            </div>
        </div>
    </div>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="decline()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success" (click)="createInvoicesFromWorktime()"><i class="fas fa-save"></i> Save</button>
</div>