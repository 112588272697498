<div class="bg-dark custom-navbar">
    <div class="d-flex align-items-center mb-2">
        <div class="me-auto">
            <div class="text-white text-decoration-none">
                <a routerLink="">
                    <img src="assets/logos/premotec_logo_horizontal.png" class="navbar-brand img-fluid" width="170">
                </a>
            </div>
        </div>

        <!-- Change Language -->
        <div class="d-flex align-items-center me-2">
            <div class="dropdown me-3 cursor-pointer" dropdown>
                <a class="d-flex text-white dropdown-toggle align-items-center" data-bs-toggle="dropdown" dropdownToggle>
                    <span class="fas fa-globe me-1"></span> {{currentLanguage | titlecase }}
                </a>
                <ul class="dropdown-menu dropdown-menu-dark text-small shadow" *dropdownMenu>
                    <li class="cursor-pointer" role="menuitem"><a class="dropdown-item" (click)="setLanguage('de')">DE</a></li>
                    <li class="cursor-pointer" role="menuitem"><a class="dropdown-item" (click)="setLanguage('en')">EN</a></li>
                </ul>
            </div>
        </div>

        <!-- Login/Logout -->
        <div class="d-flex align-items-center">
            <div class="dropdown me-3 cursor-pointer" dropdown>
                <a class="d-flex align-items-center text-white dropdown-toggle" data-bs-toggle="dropdown" [ngClass]="{'dropdown-toggle' : userInformation}" dropdownToggle>
                    <i class="fa fa-user me-1"></i>
                    {{ userInformation.firstName }}
                </a>

                <ng-container *ngIf="userInformation" >
                    <ul class="dropdown-menu dropdown-menu-dark text-small shadow" *dropdownMenu>
                        <li>
                            <a class="dropdown-item cursor-pointer" (click)="logOut()">
                                <i class="fa fa-sign-out-alt"></i>
                                {{ 'Logout' | transloco}}
                            </a>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>
    </div>
</div>