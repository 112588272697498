import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'activeProject' })
export class ActiveProjectPipe implements PipeTransform {

	transform(value: any[], arg): unknown {
		if (!arg) {
			return value?.filter(x => x.isActive === true)
		}
		else {
			return value;
		}
	}
}    		
