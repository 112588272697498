import { Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridBaseComponent } from "src/app/shared/ag-grid-shared/ag-grid-base.component";
import { Employee } from "src/app/billing/models/employee/employee.model";
import { EmployeeService } from "src/app/billing/services/employee.service";
import { GridService } from "src/app/shared/services/grid.service";
import { AgGridEmployeeActionButtonsComponent } from "./ag-grid-employee-action-buttons/ag-grid-employee-action-buttons.component";
import { EmployeeGridColumnService } from "./employee-grid-column.service";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
    templateUrl: './employee-overview.component.html',
    styleUrls: ['./employee-overview.component.scss'],
    providers: [GridService]
})
export class EmployeeOverviewComponent extends AgGridBaseComponent implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    modalRef?: BsModalRef;
    public employees: Employee[];
    public loading: boolean;
    public importing: boolean;
    public showInvisibleEmployees: boolean;
    public dataTemplate: any;

    form: UntypedFormGroup;
    showInactive: boolean;
    activeSwitch = new UntypedFormControl()

    public defaultColDef: ColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true
    };

    constructor(
        private gridService: GridService,
        private employeeService: EmployeeService,
        private employeeGridColumnService: EmployeeGridColumnService,
        private router: Router,
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            switch: [null, Validators.requiredTrue]
        })
        this.updateData();
        this.initGrid();
        this.activeSwitch.valueChanges
            .subscribe((res) => {
                this.showActiveOrAll(res);
            })
    }

    private async initGrid() {
        this.addFrameWorkComponent('editButton', AgGridEmployeeActionButtonsComponent);
        const columnDefs = await this.employeeGridColumnService.getColumns();
        this.gridOptions.columnDefs = columnDefs;
        this.gridOptions.suppressRowTransform = true;
        this.gridConfigReady = true;
    }

    public onGridReady(parameters: GridReadyEvent): void {
        super.onGridReady(parameters);
        this.showActiveOrAll(false);
        this.autoSize();
        this.fillGrid()
    }

    updateData() {
        this.loading = true;
        this.employeeService.getEmployees().subscribe(
            x => {
                this.employees = x;
                this.loading = false;
            });
    }

    openDetails(id: string) {
        this.router.navigateByUrl('/employee/' + id);
    }

    showActiveOrAll(checked: boolean) {
        var isActiveFilterComponent = this.gridApi.getFilterInstance('isActive')!;
        if (checked) {
            isActiveFilterComponent.setModel({ type: 'equals', filter: '' });
        }
        else {
            isActiveFilterComponent.setModel({ type: 'equals', filter: 'true' });
        }
        this.gridApi.onFilterChanged();
    }

    autoSize() {
        const allColumnIds = [];
        this.gridOptions.columnApi.getAllColumns().forEach((column) => {
            if (!column['colDef'].width) {
                allColumnIds.push(column.getId());
            }
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
    }

    private fillGrid() {
        if (!this.employees || this.employees == null) { return; }

        this.gridService.processGrid(this.agGrid, this.employees, this.constructor.name);
        this.agGrid.api.sizeColumnsToFit();
    }

}