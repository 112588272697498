import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  reqWithToken;

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('app_token');

    // Check for Login because without, it will route to unauthorized component
    if (req.url.includes('account/auth')) return next.handle(req);

    if (!token) {
      this.router.navigateByUrl('/unauthorized');
      return next.handle(req);
    }

    this.reqWithToken = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });

    return next.handle(this.reqWithToken);
  }

}
