import { Component, OnInit, TemplateRef} from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import { BillingGroup } from "src/app/billing/models/billing-group/billing-group.model";
import { Customer } from "src/app/billing/models/customer/customer.model";
import { Employee } from "src/app/billing/models/employee/employee.model";
import { Project } from "src/app/billing/models/project/project.model";
import { BillingGroupService } from "src/app/billing/services/billing-group.service";
import { CustomerService } from "src/app/billing/services/customer.service";
import { ProjectService } from "src/app/billing/services/project.service";
import { ConfirmationModalComponent } from "src/app/shared/components/confirmation-modal/confirmation-modal.component";
import { EditCustomerDetailsModalComponent } from "./edit-customer-details-modal/edit-customer-details-modal.component";
import { ColDef} from "ag-grid-community";
import { BaseComponent } from "@premotec/ngx-essentials";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent extends BaseComponent implements OnInit {

    modalRef?: BsModalRef;
    public customer: Customer;
    public billingGroups: BillingGroup[];
    public projects: Project[];
    public project: Project;
    public employees: Employee[];
    public loading: boolean = true;
    public importing: boolean;
    public id: string;
    public dataTemplate: any;
    public projectToChange: Project; //Project dragged in list
    showInactiveProjects: boolean = false;


    public defaultColDef: ColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true
    };

    constructor(
        private customerService: CustomerService,
        private projectService: ProjectService,
        private billingGroupService: BillingGroupService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,
        private toastr: ToastrService
    ) {
        super();

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.id = params['id'];
            }
        });
    }

    ngOnInit(): void {
        this.loadData();
    }


    openProjectDetails(projectId: string) {
        this.router.navigateByUrl('/customer/' + this.id + "/" + projectId);
    }

    loadDetails() {
        this.loading = true;
        this.customerService.getCustomerDetails(this.id).subscribe(
            x => {
                this.customer = x
                this.loading = false;
            });
    }

    loadProjects() {
        this.customerService.getCustomerProjects(this.id).subscribe(
            x => {
                x = x.sort((a, b) => (a.name ?? '').localeCompare((b.name ?? '')));
                this.projects = x;
            });
    }

    loadBillingGroups() {
        this.billingGroupService.getAllBillingGroups().subscribe(
            x => {
                this.billingGroups = x;
            })
    }

    loadEmployees() {
        this.customerService.getCustomerEmployees(this.id).subscribe(
            x => {
                x = x.sort((a, b) => (a.name ?? '').localeCompare((b.name ?? '')));
                this.employees = x;
            });
    }

    loadData() {
        this.loadBillingGroups();
        this.loadDetails();
        this.loadProjects();
        this.loadEmployees();

    }

    hasEmployees(): boolean {
        if (!this.employees) return false;
        if (this.employees.length == 0) return false
        return true;
    }

    updateCustomer() {
        this.customerService.updateCustomer(this.customer.id, this.customer).subscribe(x => {
            this.customer = x;
        });
    }

    updateProject(project) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            class: 'modal-lg',
            keyboard: false,
            initialState: {
                project: project,
                billinGroups: this.billingGroups
            },
        };
        const bsModalRef = this.modalService.show(EditCustomerDetailsModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(x => {
                this.loadProjects();
            })
    }

    toggleShowInactiveProjects() {
        this.showInactiveProjects = !this.showInactiveProjects;
    }

    changeVisibility(customerToChange: Customer, currentState: boolean) {

        let customerToPut = { ...customerToChange };
        let question = 'invisible';
        if (!currentState) {
            question = 'visible'
        }
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                title: "Visibility",
                body: `Do you want to set it <strong> ${question} </strong>?`,
                okayButton: "OK",
                cancelButton: "Cancel",
            }
        };
        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                customerToPut.visibleForBilling = !currentState;

                this.customerService.updateCustomer(customerToPut.id, customerToPut).subscribe(() => {
                    this.customer.visibleForBilling = !currentState;
                    this.toastr.success('Customer visibility successfully updated!');
                    })
                }
            )
        bsModalRef.content?.declined.pipe(take(1))
            .subscribe(() => {
                this.customer.visibleForBilling = !this.customer.visibleForBilling;
                }
            )
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    getBillingGroupName(id: String) {
        let name;
        if (this.billingGroups)
            this.billingGroups.forEach((x) => {
                if (x.id === id) {
                    name = x.name;
                }
            });
        if (name)
            return name;
    }

    changeActiveState(id, event) {
        this.projectService.updateActivityProject(id, event).subscribe(() => {
            this.toastr.success('Project visibility successfully updated!');
        },
            (err) => {
                this.loadProjects();
                this.toastr.error('An error occured while updating!');
            })
    }

}