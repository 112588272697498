<!-- Show Logo on white Background on LoggedOut State -->
<div *ngIf="!userInformation" class="vh-100 d-flex align-items-center justify-content-center">
  <img src="assets/logos/premotec_logo_horizontal.png" class="navbar-brand img-fluid custom-img" width="170">
</div>


<ng-container *ngIf="userInformation">
    <app-top-navbar [userInformation]="userInformation"></app-top-navbar>
    <div class="main-wrapper">
      <div class="side-menu">
        <app-side-navbar (isCollapsed)="toggleCollapse(!$event)"></app-side-navbar>
      </div>
      <div class="main-content" [ngClass]="collapsed ? 'sidebar-collapsed-width' : 'sidebar-width'">
        <router-outlet></router-outlet>
      </div>
    </div>
</ng-container>