<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left" *ngIf="createOrUpdateMode == 'create'">Create Position</h4>
    <h4 class="modal-title pull-left" *ngIf="createOrUpdateMode == 'update'">Edit Position</h4>
</div>

<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form">
        <div class="row">
                <div class="mb-2 col-12">
                    <label class="form-label" for="description">Description</label>
                    <input type="text" class="form-control" id="description"
                        [ngClass]="validateField('description')"
                        formControlName="description">
                    <div class="invalid-feedback">This field is required.</div>
                </div>

                <div class="mb-2 col-4" >
                    <label class="form-label"for="vatPct">Vat %</label>
                    <input type="number" class="form-control" id="vatPct"
                        [ngClass]="validateField('vatPct')"
                        formControlName="vatPct">
                    <div class="invalid-feedback">This field is required.</div>
                </div>

                <div class="mb-2 col-4">
                    <label class="form-label" for="amount">Amount</label>
                    <input type="number" class="form-control" id="amount"
                        [ngClass]="validateField('amount')"
                        formControlName="amount">
                    <div class="invalid-feedback">This field is required.</div>
                </div>

                <div class="mb-2 col-4">
                    <label class="form-label" for="price">Price</label>
                    <input type="number" class="form-control" id="price"
                        [ngClass]="validateField('price')"
                        formControlName="price">
                    <div class="invalid-feedback">This field is required.</div>
                </div>
        </div>
    </form>
</div>

<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>