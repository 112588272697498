import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CurrencyEnum } from "../models/enums/currency.enum";
import { Invoice } from "../models/invoice/invoice.model";
import { InvoiceByWorktimeModel } from "../models/invoice/invoice-by-worktime.model";
import { InvoicesByWorktimeModel } from "../models/invoice/invoices-by-worktime.model";
import { InvoicePositionUpdate } from "../models/invoice/invoice-position-update.model";
import { InvoiceBasics } from "../models/invoice/invoice-basics.model";
import { InvoicePosition } from "../models/invoice/invoice-position.model";
import { Bank } from "../models/invoice/bank.model";
import { InvoicePreview } from "../models/invoice/invoice-preview.model";
import { InvoiceByDates } from "../models/invoice/invoice-by-dates.model";

@Injectable({
    providedIn: 'root'
})

export class InvoiceService {

    constructor(private http: HttpClient) { }

    public getInvoices(): Observable<Invoice[]> {
        return this.http.get<Invoice[]>(`${environment.api}/invoice`);
    }

    public getInvoiceById(id: string): Observable<Invoice> {
        return this.http.get<Invoice>(`${environment.api}/invoice/${id}`);
    }

    public getVat(): Observable<number> {
        return this.http.get<number>(`${environment.api}/invoice/vat`);
    }

    public getLastInvoiceNr(): Observable<number> {
        return this.http.get<number>(`${environment.api}/invoice/lastInvoiceNr`);
    }

    public getPaymentTermInDays(): Observable<number> {
        return this.http.get<number>(`${environment.api}/invoice/paymentTermInDays`);
    }

    public getBankByCurrency(currency: CurrencyEnum): Observable<Bank> {
        return this.http.get<Bank>(`${environment.api}/invoice/bank/${currency}`);
    }

    public createInvoiceFromWorktime(invoice: InvoiceByWorktimeModel): Observable<string> {
        return this.http.post<string>(`${environment.api}/invoice/byWorktime`, invoice);
    }

    public createInvoicesFromWorktime(invoices: InvoicesByWorktimeModel): Observable<Invoice[]> {
        return this.http.post<Invoice[]>(`${environment.api}/invoice/invoicesByWorktime`, invoices);
    }

    public createInvoicePosition(invoiceId: string, newInvoicePosition: InvoicePositionUpdate): Observable<InvoicePosition> {
        return this.http.post<InvoicePosition>(`${environment.api}/invoice/${invoiceId}/position`, newInvoicePosition);
    }

    public getInvoicePdf(invoiceId: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/invoiceFile/${invoiceId}/pdf`);
    }

    public getActivityJournal(invoiceId: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/invoiceFile/${invoiceId}/activity-journal`);
    }

    public getMultiFilesZipped(invoiceIds: Array<string>): Observable<string>{
        return this.http.post<string>(`${environment.api}/invoiceFile/multifile`, invoiceIds);
    }

    public generateInvoiceFilesByIds(ids: string[]): Observable<string> {
        return this.http.post<string>(`${environment.api}/invoiceFile/generate`, ids);
    }

    /*public createInvoicePdf(invoiceId: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/invoice/${invoiceId}/pdf`);
    }

    public createActivityJournal(invoiceId: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/invoice/${invoiceId}/activity-journal`);
    }*/

    public updateInvoice(id: string, invoiceUpdate: InvoiceBasics) {
        return this.http.put<Invoice>(`${environment.api}/invoice/${id}`, invoiceUpdate);
    }

    public updateInvoicePosition(id: string, invoicePosition: InvoicePositionUpdate) {
        return this.http.put<InvoicePosition>(`${environment.api}/invoicePosition/${id}`, invoicePosition);
    }

    public updateInvoicePositionsSort(id: string, invoicePositionsSort: Object) {
        return this.http.put<InvoicePosition>(`${environment.api}/invoicePosition/${id}/sort-positions`, invoicePositionsSort);
    }

    public deleteInvoice(id: string): Observable<Invoice> {
        return this.http.delete<Invoice>(`${environment.api}/invoice/${id}`);
    }

    public deleteInvoicePosition(id: string): Observable<Invoice> {
        return this.http.delete<Invoice>(`${environment.api}/invoicePosition/${id}`);
    }

    public deleteInvoiceFiles(id: string): Observable<Invoice> {
        return this.http.delete<Invoice>(`${environment.api}/invoiceFile/${id}`);
    }

    public createInvoicePreview(invoicePreview: InvoiceByDates): Observable<InvoicePreview[]> {
        return this.http.post<InvoicePreview[]>(`${environment.api}/invoice/preview`, invoicePreview);
    }

    public getInvoiceSummary(invoicePreview: InvoiceByDates): Observable<InvoicePreview[]> {
        return this.http.post<InvoicePreview[]>(`${environment.api}/invoice/summary`, invoicePreview);
    }
}
