import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { InvoiceService } from "src/app/billing/services/invoice.service";
import { CreateInvoicePreviewModalComponent } from "./create-invoice-preview-modal/create-invoice-preview-modal.component";
import { take } from "rxjs/operators";
import { BaseComponent } from "@premotec/ngx-essentials";
import { InvoicePreview } from "src/app/billing/models/invoice/invoice-preview.model";
import { InvoicePosition } from "src/app/billing/models/invoice/invoice-position.model";
import { InvoicePositionsSortUpdate } from "src/app/billing/models/invoice/invoice-position-sort-update.model";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DeleteConfirmationModalComponent } from "src/app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component";
import { CreateOrUpdatePositionModalComponent } from "../invoice-detail/createOrUpdate-position-modal/createOrUpdate-position-modal.component";
import { Project } from "src/app/billing/models/project/project.model";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-invoice-preview',
	templateUrl: './invoice-preview.component.html',
	styleUrls: ['./invoice-preview.component.scss']
})

export class InvoicePreviewComponent extends BaseComponent implements OnInit {
    public loading: boolean = true;
    public canceled: boolean = false;
    public invoicePreviews: InvoicePreview[];

    public startDate: Date;
    public endDate: Date;

    public invoiceDate: Date;
    formSubmitting: boolean = false;

    constructor(
        private invoiceService: InvoiceService,
        private modalService: BsModalService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
    ) {super();}

    ngOnInit(): void {
        this.loading = true;
        this.createInvoicesPreview();
    }
    
    createInvoicesPreview() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {},
        };
        const bsModalRef = this.modalService.show(CreateInvoicePreviewModalComponent, initialState);
    
        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((invoicePreviewCreate) => {
                this.invoiceService.createInvoicePreview(invoicePreviewCreate)
                    .subscribe(x => {
                        this.invoicePreviews = x.sort((a, b) => a.billingGroupName.localeCompare(b.billingGroupName));
                        
                        this.startDate = invoicePreviewCreate.startDate;
                        this.endDate = invoicePreviewCreate.endDate;

                        this.invoicePreviews.forEach(invoicePreview => {
                            this.sortPositions(invoicePreview.id);
                            this.calculatePricesForInvoice(invoicePreview.id);
                        })

                        this.loading = false;
                    },
                    (err) => {
                        this.toastr.error('An error ocurred!')
                        this.loading = false;
                    }
                    )
            }
        )

        bsModalRef.content?.declined.pipe(take(1))
        .subscribe(() => {
                this.loading = false;
                this.canceled = true;
        });
    }

    sortPositions(id: string){
        let invoicePreview = this.invoicePreviews.find(preview => preview.id === id);

        let positions: InvoicePosition[] = [];
        let isInvoicePositionsSorted = false;
            //check if position is already sorted
            if (invoicePreview.positions.length > 1 && !(invoicePreview.positions[0].sort === 0 && invoicePreview.positions[1].sort === 0)) {
                isInvoicePositionsSorted = true
            }
            for (let i = 0; i < invoicePreview.positions.length; i++) {
                if (!isInvoicePositionsSorted) {
                    invoicePreview.positions[i].sort = i
                }
                positions.push(invoicePreview.positions[i]);
            }
        invoicePreview.positions = positions;
    }

    createPosition(invoice: InvoicePreview) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                vatDefault: invoice.vatDefault,
                createOrUpdateMode: 'create'
            },
        };
        const bsModalRef = this.modalService.show(CreateOrUpdatePositionModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((invoicePositionData) => {

            let invoicePreview = this.invoicePreviews.find(preview => preview.id === invoice.id);
            
            let createdInvoicePosition: InvoicePosition ={
                id: '',
                invoiceId: '',
                projectId: '',
                description: invoicePositionData.description,
                amount: invoicePositionData.amount,
                vatPct: invoicePositionData.vatPct,
                price: invoicePositionData.price,
                project: new Project,
                sort: invoicePreview.positions.length
            }

            if (invoicePreview) {
                invoicePreview.positions.push(createdInvoicePosition);
                this.calculatePricesForInvoice(invoicePreview.id);
                this.toastr.success('Position successfully created!');
            }
        })
    }

    updatePosition(id: string, position: InvoicePosition, index: number) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                invoicePosition: position,
                createOrUpdateMode: 'update'
            },
        };
        const bsModalRef = this.modalService.show(CreateOrUpdatePositionModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((invoicePositionData) => {
                let updatedInvoicePosition: InvoicePosition ={
                    id: '',
                    invoiceId: '',
                    projectId: '',
                    description: invoicePositionData.description,
                    amount: invoicePositionData.amount,
                    vatPct: invoicePositionData.vatPct,
                    price: invoicePositionData.price,
                    project: new Project,
                    sort: position.sort
                }
                let invoicePreview = this.invoicePreviews.find(preview => preview.id === id);
                if(invoicePreview){
                    invoicePreview.positions.splice(index, 1, updatedInvoicePosition);
                    this.calculatePricesForInvoice(invoicePreview.id);
                    this.toastr.success('Position successfully updated!');
                }
            }
        )
    }

    deletePosition(id: string, positionId: string, index: number) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                title: "Delete position",
                body: "Are you sure?",
                okayButton: "OK",
                cancelButton: "Cancel",
            }
        };
        const bsModalRef = this.modalService.show(DeleteConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                let invoicePreview = this.invoicePreviews.find(preview => preview.id === id);
                if(invoicePreview){
                    invoicePreview.positions.splice(index, 1);
                    this.calculatePricesForInvoice(invoicePreview.id);
                    this.toastr.success('Position successfully deleted!');
                }
            }
        )
    }

    calculatePricesForInvoice(id: string) {
        let invoicePreview = this.invoicePreviews.find(preview => preview.id === id);
        if (invoicePreview) {
            invoicePreview.invoiceTotalPrice = 0;
            invoicePreview.invoiceTotalVat = 0;
            invoicePreview.invoiceTotalPriceVat = 0;
    
            invoicePreview.positions.forEach(x => {
                invoicePreview.invoiceTotalPrice += x.amount * x.price;
                invoicePreview.invoiceTotalVat += (x.amount * x.price) / 100 * x.vatPct
            });
            invoicePreview.invoiceTotalPriceVat = invoicePreview.invoiceTotalPrice + invoicePreview.invoiceTotalVat
        }
    }

    changedSortPosition(event: CdkDragDrop<string[]>, id: string) {
        let invoicePreview = this.invoicePreviews.find(preview => preview.id === id);
        moveItemInArray(invoicePreview.positions, event.previousIndex, event.currentIndex);
        invoicePreview.positions[event.previousIndex].sort = event.currentIndex;
        if (event.previousIndex > event.currentIndex) {
            for (let i = event.currentIndex; i < invoicePreview.positions.length; i++) {
                invoicePreview.positions[i].sort = i;
            }
        }
        if (event.previousIndex < event.currentIndex) {
            for (let i = event.currentIndex; i >= 0; i--) {
                invoicePreview.positions[i].sort = i;
            }
        }
        this.updateSortPositions(invoicePreview);
    }

    updateSortPositions(invoicePreview: InvoicePreview) {
        let invoicePositionsUpdate: InvoicePositionsSortUpdate[] = [];

        let invoicePositionsMap = new Object();
        invoicePreview.positions.forEach((x: any) => {
            let temp: InvoicePositionsSortUpdate = {
                id: x.id,
                sort: x.sort
            }
            invoicePositionsUpdate.push(temp)
            invoicePositionsMap[x.id] = x.sort;
        })
    }

    sortBy(prop: string, id: string) {
        let invoicePreview = this.invoicePreviews.find(preview => preview.id === id);
        return invoicePreview.positions.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
}

