import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { AuthErrorComponent } from './security/components/auth-error/auth-error.component';
import { LogoutComponent } from './security/components/logout/logout.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'billing', pathMatch: 'full' },
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then((m) => m.BillingModule),
    canActivate: [MsalGuard],
  },
  { path: 'billing/logout', component: LogoutComponent },
  { path: 'unauthorized', component: AuthErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
