<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">
        <div class="card sticky-toolbar mb-3">
            <div class="card-header ">
                <div class="switch-container btn-group me-2">
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="activeSwitch" type="checkbox" role="switch" [formControl]="activeSwitch"
                            unchecked>
                    </div>
                    <label for="activeSwitch" class="form-check-label">Show Inactive</label>
                </div>
            </div>
        </div>

        <div class="d-flex pb-2 ">
            <h2 class="pe-3">Employees</h2>
        </div>

        <div class="container-flat">
            <div class="row">
                <div class="col-sm">
                    <ag-grid-angular 
                        #agGrid 
                        style="width: 100%; height: calc(100vh - 240px);" 
                        class="ag-theme-alpine"
                        [gridOptions]="gridOptions" 
                        (gridReady)="onGridReady($event)" 
                        [rowData]="employees"
                        [defaultColDef]="defaultColDef"
                        enableCellTextSelection="true" 
                        [tooltipShowDelay]="0"
                        [enableBrowserTooltips]="true">
                    </ag-grid-angular>
                    <app-ag-grid-footer></app-ag-grid-footer>
                </div>
            </div>
        </div>

    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>