import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { BillingGroup } from 'src/app/billing/models/billing-group/billing-group.model';
import { Project } from 'src/app/billing/models/project/project.model';
import { BillingGroupService } from 'src/app/billing/services/billing-group.service';
import { ProjectService } from 'src/app/billing/services/project.service';
import { EditCustomerDetailsModalComponent } from '../../customer/customer-details/edit-customer-details-modal/edit-customer-details-modal.component';

@Component({
	selector: 'app-project-overview',
	templateUrl: './project-overview.component.html',
	styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit {

	public loading: boolean;
	activeSwitch = new UntypedFormControl()
	showInactive: boolean;
	showIncomplete:boolean = false;
	showInactiveCustomer:boolean = false;
	public projects: Project[];
	public billingGroups: BillingGroup[];
	public startDate: Date;
	public endDate: Date;

	public lastQuarter: number;
	public currentQuarter: number;
	public nextQuarter: number;

	public currentQuarterYear: number;
	public lastQuarterYear: number;
	public nextQuarterYear: number;

	constructor(
		private projectService: ProjectService,
		private billingGroupService: BillingGroupService,
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private toastr: ToastrService
	) {
		this.route.queryParams.subscribe(params => {
            if (params['startdate'] && params['enddate']) {                
				this.startDate = new Date(params['startdate']);
				this.endDate = new Date(params['enddate']);
				if(params['incompleteprojects']==="true"){
					this.showIncomplete = true;
				}
				this.loadProjects();
            } else {
				this.initDefaultDates();
			}
        });
	}

	ngOnInit(): void {
		this.setQuarters();
	}

	loadProjects() {
		this.loading = true;
		this.projectService.getProjects(this.getShortDate(this.startDate), this.getShortDate(this.endDate)).subscribe((x: any) => {
			this.projects = x;
			this.loading = false;
		});
	}

	//generate a string with format yyyy-MM-dd
	getShortDate(date: Date) {
		var dd = date.getDate();
		var mm = date.getMonth() + 1;
		var yyyy = date.getFullYear();
		return  yyyy + '-' + this.fillZero(mm) + '-' + this.fillZero(dd);
	}

	//return a string with a 0 before,  when the number have just one digit
	fillZero(number: number) {
		if (number < 10) {
			return '0' + number;
		}
		return number;
	}

	loadData() {
		this.loadBillingGroups();
	}

	loadBillingGroups() {
		this.billingGroupService.getAllBillingGroups().subscribe(
			x => {
				this.billingGroups = x;
			})
	}

	initDefaultDates() {
		const dateNow = new Date();
		this.startDate = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1);
		this.endDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 0);
	}

	updateProject(project) {
		const initialState: ModalOptions = {
			backdrop: 'static',
			class: 'modal-lg',
			keyboard: false,
			initialState: {
				project: project,
				billingGroups: this.billingGroups
			},
		};
		const bsModalRef = this.modalService.show(EditCustomerDetailsModalComponent, initialState);

		bsModalRef.content?.confirmed.pipe(take(1))
			.subscribe(x => {
				this.loadProjects();
			})
	}

	changeActiveState(id, event) {
        this.projectService.updateActivityProject(id, event).subscribe(() => {
            this.toastr.success('Project visibility successfully updated!');
        },
            (err) => {
                this.loadProjects();
                this.toastr.error('An error occured while updating!');
            })
    }

	setLastMonthDates() {
		this.initDefaultDates();
	}

	setCurrentMonthDates() {
		const dateNow = new Date();
		this.startDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
		this.endDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
	}

	setCurrentQuarterDates() {
		const dateNow = new Date();
		this.currentQuarter = Math.floor(dateNow.getMonth() / 3);
		this.startDate = new Date(dateNow.getFullYear(), this.currentQuarter * 3, 1);
		this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 3, 0);
	}

	setLastQuarterDates() {
		const dateNow = new Date();
		const lastQuarterEndDate = this.getStartDate(dateNow);
		lastQuarterEndDate.setDate(lastQuarterEndDate.getDate() - 1);
		this.lastQuarter = this.getQuarter(lastQuarterEndDate);
		this.startDate = this.getStartDate(lastQuarterEndDate);
		this.endDate = lastQuarterEndDate;

	}

	setNextQuarterDates() {
		const dateNow = new Date();
		const nextQuarterStartDate = this.getEndDate(this.getStartDate(dateNow));
		nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() + 1);

		this.startDate = nextQuarterStartDate;
		this.endDate = this.getEndDate(this.startDate);
	}

	setQuarters() {

		const dateNow = new Date();

		this.currentQuarter = (this.getQuarter(dateNow));
		this.currentQuarterYear = dateNow.getFullYear();

		const lastQuarterEndDate = this.getStartDate(dateNow);
		lastQuarterEndDate.setDate(lastQuarterEndDate.getDate() - 1);
		this.lastQuarter = (this.getQuarter(lastQuarterEndDate));
		this.lastQuarterYear = lastQuarterEndDate.getFullYear();

		const nextQuarterStartDate = this.getEndDate(this.getStartDate(dateNow));
		nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() + 1);
		this.nextQuarter = (this.getQuarter(nextQuarterStartDate));
		this.nextQuarterYear = nextQuarterStartDate.getFullYear();
	}

	getQuarter(date: Date) {
		return Math.floor(date.getMonth() / 3);
	}

	getStartDate(date: Date) {
		const quarter = this.getQuarter(date);
		return new Date(date.getFullYear(), quarter * 3, 1);
	}

	getEndDate(date: Date) {
		return new Date(date.getFullYear(), date.getMonth() + 3, 0);
	}

}
