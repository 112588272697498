import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { LastTimeTrackerImportDates } from "../../models/import/lastTimeTrackerImportDates.model";
import { ImportService } from "../../services/import.service";
import { BaseComponent } from "@premotec/ngx-essentials";


@Component({
    templateUrl: './import-overview.component.html',
    styleUrls: ['./import-overview.component.scss']
})
export class ImportOverviewComponent extends BaseComponent implements OnInit {

    public loading: boolean;
    public lastImportDates: LastTimeTrackerImportDates;

    public startDate: Date;
    public endDate: Date;
    constructor(
        private importService: ImportService,
        private toastrService: ToastrService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initDefaultDates();
        this.loading = true;
        this.importService.getLastImportDate()
            .subscribe((lastTimeTrackerImportDates: LastTimeTrackerImportDates) => {
                this.lastImportDates = lastTimeTrackerImportDates;
                this.loading = false;
        });
    }

    importData() {
        this.loading = true;
        this.importService.triggerImport(this.startDate, this.endDate)
            .subscribe((lastTimeTrackerImportDates: LastTimeTrackerImportDates) => {
                this.lastImportDates = lastTimeTrackerImportDates;
                this.toastrService.success('Successfully imported!');
                this.loading = false;
                },
                err => {
                    this.toastrService.error('An Error ocurred!');
                    this.loading = false;
                }
            );
    }

    initDefaultDates() {
        const dateNow = new Date();
        this.startDate = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1);
        this.endDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 0);
    }
}
