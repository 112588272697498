import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DeleteConfirmationModalComponent } from './components/delete-confirmation-modal/delete-confirmation-modal.component';
import { AgGridFooterComponent } from './components/ag-grid-footer/ag-grid-footer.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { SafePipe } from './pipes/safe.pipe';
import { TwoDecimalNumberDirective } from './directives/two-decimal-number.directive';
import { PmtDatepickerModule } from '@premotec/pmt-datepicker';
import { PmtEssentialsModule } from '@premotec/pmt-essentials';
import { PmtBootstrapModule } from '@premotec/pmt-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoRootModule } from '../transloco/transloco-root.module';

const components = [
  ConfirmationModalComponent, 
  DeleteConfirmationModalComponent,
  AgGridFooterComponent,
  LoadingModalComponent,
];

const pmtModules = [
  PmtDatepickerModule,
  PmtEssentialsModule,
  PmtBootstrapModule
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoRootModule,
    ToastrModule.forRoot(),
    pmtModules
  ],
  declarations: [
    components,
    AgGridFooterComponent,
    TwoDecimalNumberDirective,
    // AdditionalListBlockComponent
  ],
  providers: [
    DatePipe,
    SafePipe
  ],
  exports: [
    components,
    ReactiveFormsModule,
    ToastrModule,
    AgGridFooterComponent,
    TwoDecimalNumberDirective,
    pmtModules,
  ]
})


export class SharedModule { }