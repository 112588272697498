export const environment = {
  production: false,
  api: 'https://api.billing-test.premotec.ch',
  msal: {
    scopeUri: ['api://96178809-8e4f-4b13-b00c-065b865cd820/billing'],
    uiClienId: '47e069b4-5eae-4b11-b0aa-ce348843da7e',
    tenantId: '1cf9f76d-0bf6-45b1-b4bd-ecfe2ff24050',
    clientId: '29fc03da-7861-4521-be8f-9b2ffce5623d',
    redirectUrl: 'https://billing-test.premotec.ch'
  },
  hangfire: 'https://api.billing-test.premotec.ch/hangfire'
};
