<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">

        <div class="d-flex pb-2 ">
            <h2 class="pe-3">Projects with Worktimes in period</h2>
        </div>

        <div class="card mb-3">
            <div class="card-header sticky-toolbar">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row row-datepicker">
                                <div class="col-lg-6">
                                    <div class="picker-width-fixed">
                                        <label for="startDate">Start date</label>
                                        <pmt-datepicker [(ngModel)]="startDate" [id]="'startDate'"></pmt-datepicker>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="picker-width-fixed">
                                        <label for="startDate">End date</label>
                                        <pmt-datepicker [(ngModel)]="endDate" [id]="'endDate'"></pmt-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col buttons-h-list">
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setLastMonthDates()">Last month</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentMonthDates()">Current month</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setLastQuarterDates()">Q{{this.lastQuarter + 1}} {{this.lastQuarterYear}}</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentQuarterDates()">Q{{this.currentQuarter + 1}} {{this.currentQuarterYear}}</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setNextQuarterDates()">Q{{this.nextQuarter + 1}} {{this.nextQuarterYear}}</button>
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col">
                                    <button type="button" class="btn btn-primary" (click)="loadProjects();">
                                        <i class="fas fa-search"></i> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">                            
                            <div class="switch-container btn-group">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" id="activeSwitch" type="checkbox" role="switch" [(ngModel)]="showIncomplete">
                                </div>
                                <label for="activeSwitch" class="form-check-label">Filter for incomplete projects</label>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="switch-container btn-group">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" id="activeCostumerSwitch" type="checkbox" role="switch" [(ngModel)]="showInactiveCustomer">
                                </div>
                                <label for="activeCostumerSwitch" class="form-check-label">Include inactive customers</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="col-switch">Active</th>
                            <th>Project</th>
                            <th>Billing Group</th>
                            <th>Hourly rate project</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let project of projects | completeProject : showIncomplete : showInactiveCustomer">
                            <td>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                    [(ngModel)]="project.isActive" disabled>
                                </div>
                            </td>
                            <td>{{project.customer.name}} - {{project.name}}</td>
                            <td>{{project.billingGroup?.name}}</td>
                            <td>{{project.hourlyRate | number : '0.2-2'}}</td>
                            <td>
                                <button type="button" class="btn btn-sm btn-primary float-end" (click)="updateProject(project)">
                                    <i class="fas fa-edit"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>