<div class="card mt-2">
    <h4 class="card-header d-flex justify-content-between align-items-center">
        <div><i class="fa-solid fa-envelope fa-sm"></i> {{'EmailSettings' | transloco}}</div>
        <button type="button" class="btn btn-sm btn-primary" (click)="onEditEmailSettings()">
            <i class="fas fa-edit" tooltip="{{ 'edit' | transloco}}" placement="auto"></i>
        </button>
    </h4>
    <div class="card-body">
        <div class="row mb-2">
            <div class="col-12">
                <label for="salutation" class="form-label">{{'Salutation' | transloco}}</label>
                <textarea id="salutation" type="text" class="form-control inlineInput" rows="3" disabled [value]="billingGroup?.emailSalutation"></textarea>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <label for="recipients" class="form-label me-1">{{'Recipients' | transloco }}</label>
                <span class="badge bg-secondary">{{billingGroup?.emailRecipients.length}}</span>
                <input id="recipients" type="text" class="form-control inlineInput mb-1" disabled *ngFor="let recipient of billingGroup?.emailRecipients" [value]="recipient">
            </div>
        </div>
    </div>
</div>