import { TranslocoFallbackStrategy, TRANSLOCO_FALLBACK_STRATEGY } from '@ngneat/transloco';

export class CustomStrategy implements TranslocoFallbackStrategy {
  getNextLangs(failedLang: string): string[] {
    if (failedLang == 'de') {
      return ['en'];
    }
    return ['de'];
  }
}

export const customFallbackStrategy = {
  provide: TRANSLOCO_FALLBACK_STRATEGY,
  useClass: CustomStrategy
};
