import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Customer } from "../models/customer/customer.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Project } from "../models/project/project.model";
import { Employee } from "../models/employee/employee.model";
import { DatepickerRenderOptions } from "ngx-bootstrap/datepicker/models";


@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private http: HttpClient) { }
    
    public sendInvoicesAsEmail(invoiceIds: string[]): Observable<string> {
        return this.http.post<string>(`${environment.api}/email/sendInvoicesAsEmail`, invoiceIds);
    }

}
