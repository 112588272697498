import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountInfoModel } from '../models/account-info.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn: boolean = false;
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  public loginUserToBackend(token: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(`${environment.api}/account/auth`, { azureIdToken: token })
      .pipe(tap((res) => {
        localStorage.setItem('app_token', res['token']);
      }))
  }

  getMyAccount(): Observable<AccountInfoModel> {
    return this.http.get<AccountInfoModel>(`${environment.api}/account/me`);
  }
}
