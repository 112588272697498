<ng-container *ngIf="!loading&&!canceled">
    <div class="container-fluid mt-3">

        <div class="card mb-3 sticky-toolbar">
            <div class="card-header">
                <div class="form-group">
                    <button class="btn btn-light border me-2" type="button" [routerLink]="['/billing/invoices']" tooltip="Go back" [delay]="300" placement="bottom">
                        <i class="fas fa-arrow-left" ></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="d-flex pb-2 ">
            <h2 class="pe-3">Billing preview {{startDate | date: 'dd. MMMM yyyy'}} - {{endDate | date: 'dd. MMMM yyyy'}}</h2>
        </div>

        <div class="col-9">
            <div *ngFor="let invoice of invoicePreviews">
                <div class="card mb-3">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                          <h4>{{invoice.billingGroupName}}</h4>
                        </div>
                      </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Project name</th>
                                    <th>Amount</th>
                                    <th>Price</th>
                                    <th>Total Price</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody cdkDropList (cdkDropListDropped)="changedSortPosition($event, invoice.id)">
                                <tr *ngFor="let position of sortBy('sort', invoice.id); let i = index" cdkDrag cdkDragLockAxis="y">
                                    <td class="col">
                                        <i class="fas fa-bars" style="cursor:move" cdkDragHandle></i>
                                    </td>
                                    <td class="col-4">
                                        <input type="text" class="form-control" placeholder="name" disabled
                                            [value]="position.description">
                                    </td>

                                    <td class="col-2">
                                        <input type="text" class="form-control" placeholder="amount" disabled
                                            [value]="position.amount | currencyCustomPipe">
                                    </td>

                                    <td class="col-2">
                                        <input type="text" class="form-control" placeholder="price" disabled
                                            [value]="position.price | currencyCustomPipe">
                                    </td>

                                    <td class="col-2">{{position.amount * position.price | currencyCustomPipe}}</td>

                                    <td class="col-2 align-right">
                                        <div class="btn-group">
                                            <button class="btn btn-primary btn-sm" type="button"
                                                (click)="updatePosition(invoice.id, position, i)" tooltip="Edit Position" [delay]="300">
                                                <i class="fas fa-edit"></i>
                                            </button>
                                            <button type="button" class="btn btn-danger btn-sm"
                                                (click)="deletePosition(invoice.id, position.id, i)" tooltip="Delete Position" [delay]="300">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3"></td>
                                    <td class="align-right">
                                        Total excl. VAT<br />
                                        <strong>incl. VAT</strong>
                                    </td>
                                    <td>
                                        {{invoice.invoiceTotalPrice | currencyCustomPipe}}<br />
                                        <strong>{{invoice.invoiceTotalPriceVat | currencyCustomPipe}}</strong>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-primary btn-sm float-end" (click)="createPosition(invoice)" tooltip="Create Position" [delay]="300">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!loading&&canceled">
    <div class="container-fluid mt-3">
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>