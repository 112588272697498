import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GridReadyEvent, RowNode } from "ag-grid-community";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import { AgGridBaseComponent } from "src/app/shared/ag-grid-shared/ag-grid-base.component";
import { BillingGroup } from "src/app/billing/models/billing-group/billing-group.model";
import { Invoice } from "src/app/billing/models/invoice/invoice.model";
import { InvoiceService } from "src/app/billing/services/invoice.service";
import { AgGridEditButtonComponent } from "./ag-grid-edit-button/ag-grid-edit-button.component";
import { CreateInvoiceModalComponent } from "./create-invoice-modal/create-invoice-modal.component";
import { InvoiceOverviewColumnService } from "./invoice-overview-grid-column.service";
import { GridService } from "src/app/shared/services/grid.service";
import { LoadingModalComponent } from "src/app/shared/components/loading-modal/loading-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceStatusEnum } from "src/app/billing/models/enums/invoice-status.enum";
import { ConfirmationModalComponent } from "src/app/shared/components/confirmation-modal/confirmation-modal.component";
import { EmailService } from "src/app/billing/services/email.service";

@Component({
    templateUrl: './invoice-overview.component.html',
    styleUrls: ['./invoice-overview.component.scss'],
    providers: [GridService, DatePipe]
})
export class InvoiceOverviewComponent extends AgGridBaseComponent implements OnInit {

    @ViewChild('agGrid') agGrid: AgGridAngular;
    public selectedBillingGroupId: string;
    public billingGroups: BillingGroup[];
    public invoices: Invoice[] = [];
    public loading: boolean;
    public selectedRows = [];
    downloadTableAsExcelModal?: BsModalRef | null;

    downloadFilesAsZipModal?: BsModalRef | null;

    public defaultColDef: ColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true
    };

    constructor(
        private gridService: GridService,
        private emailService: EmailService,
        private invoiceOverviewColumnService: InvoiceOverviewColumnService,
        private invoiceService: InvoiceService,
        private modalService: BsModalService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.route.snapshot.routeConfig?.path == "invoices/create") {
            this.createInvoice();
        }

        this.loading = true;
        this.loadInvoices();
        this.initGrid();
    }

    private async initGrid() {
        this.addFrameWorkComponent('editButton', AgGridEditButtonComponent);
        const columnDefs = await this.invoiceOverviewColumnService.getColumns();
        this.gridOptions.columnDefs = columnDefs;
        this.gridOptions.suppressRowTransform = true;
        this.gridConfigReady = true;
    }

    public onGridReady(parameters: GridReadyEvent): void {
        this.gridApi = parameters.api;
        this.gridColumnApi = parameters.columnApi;
        super.onGridReady(parameters);
        this.fillGrid()
    }

    loadInvoices() {
        this.invoiceService.getInvoices().subscribe(x => {
            this.invoices = x;
            this.loading = false;
        });
    }

    refresh(): void {
        window.location.reload();
    }

    createInvoice() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                //vatDefault: this.invoice.vatDefault,
                createOrUpdateMode: 'create'
            },
        };
        const bsModalRef = this.modalService.show(CreateInvoiceModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((invoicesFromWorktime) => {
                this.invoiceService.createInvoicesFromWorktime(invoicesFromWorktime)
                    .subscribe(x => {
                        if (this.route.snapshot.routeConfig?.path == "invoices") {
                            this.loadInvoices();
                        }
                        else {
                            this.toastr.success('Invoices are successfully created!');
                            this.router.navigate(['billing/invoices']), x
                        }
                    },
                        err => {
                            this.toastr.error('An error ocurred!')
                        }
                    )
            }
            )
    }

    private fillGrid() {
        if (!this.invoices || this.invoices == null) { return; }

        this.gridService.processGrid(this.agGrid, this.invoices, this.constructor.name);
        this.agGrid.api.sizeColumnsToFit();
    }

    onSelectionChanged() {
        this.selectedRows = this.gridApi.getSelectedRows();
    }

    //extract fileIds of selected elements (checkbox)
    GetInvoiceIdsToZip() {
        var invoiceIdList = [];
        this.selectedRows.forEach(element => {
            invoiceIdList.push(element.id)
        });
        return invoiceIdList;
    }

    //Get file compressed in zip
    downloadZipFile() {

        const config = {
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
                title: 'Generating Multi-files Download'
            },
        }

        this.downloadFilesAsZipModal = this.modalService.show(LoadingModalComponent, config);

        this.invoiceService.getMultiFilesZipped(this.GetInvoiceIdsToZip()).subscribe(fileBytes => {

            var fileName = `Documents - ${this.DateNameStamp()}.zip`;
            const data = `data:application/pdf;base64,${fileBytes}`;
            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            this.toastr.success('Invoice Documents successfully created');
        },
            error => {
                this.toastr.error(error.error)
            }).add(() => {
                this.modalService.hide(this.downloadFilesAsZipModal?.id);
                this.downloadFilesAsZipModal = null;
            })
    }

    GetListBillingSelectionable() {
        var max;
        this.invoices.sort((a, b) => a.invoiceNr - b.invoiceNr)[0], max = this.invoices.slice(-1)[0];
        var selected = []
        this.invoices.forEach(x => {

            if ((new Date(x.invoiceDate).toISOString() === new Date(max.invoiceDate).toISOString()) && !x.isEditable) {
                selected.push(x);
            }
        })

        return selected;
    }

    HaveLastBillingSelectionable() {
        var selected = this.GetListBillingSelectionable();

        if (selected.length > 0) {
            return true
        } else {
            false
        }
    }

    clearSelection(): void {
        this.gridApi.deselectAll();
        this.selectedRows = [];
    }

    setSelectedRows() {

    // sort by Date Desc from invoices
        var sorted = this.invoices.sort((a: Invoice, b: Invoice) => {
            return +new Date(b.invoiceDate) - +new Date(a.invoiceDate);
        });
        var max = sorted[0];
        this.gridApi.forEachNode(function (node) {
            function compareDates(A: Date, B: Date) {
                var dateA = new Date(A);
                var dateB = new Date(B)
                if (dateA.getDate() === dateB.getDate() &&
                    dateA.getMonth() === dateB.getMonth() &&
                    dateA.getFullYear() === dateB.getFullYear()
                ) { return true }

                return false
            }

            node.setSelected(compareDates(node.data!.invoiceDate, max.invoiceDate));
        });
    }

    //return a string format of a date used to generate a name file
    DateNameStamp() {
        var datenow = new Date();
        var day = datenow.getDate();
        var month = datenow.getMonth() + 1;
        var year = datenow.getFullYear();

        var hour = datenow.getHours();
        var mins = datenow.getMinutes();

        return day + "_" + month + "_" + year + " " + hour + ":" + mins;
    }

    generateInvoiceFiles() {
        const allInvoicesOpen = this.selectedRows.every(row => row.statusId === InvoiceStatusEnum.Open);
        if (allInvoicesOpen) {
            const selectedIds = this.selectedRows.map(row => row.id);

            const initialState: ModalOptions = {
                backdrop: 'static',
                keyboard: false,
                initialState: {
                    body: "Documents for the selected invoices will be generated. Do you want to continue?",
                    okayButton: "YES",
                    cancelButton: "Cancel",
                }
            };
    
            const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);
    
            bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                const config = {
                    ignoreBackdropClick: true,
                    keyboard: false,
                    initialState: {
                      title: 'Generating Invoice Files'
                    },
                  }
              
                this.downloadTableAsExcelModal = this.modalService.show(LoadingModalComponent, config);
        
                this.invoiceService.generateInvoiceFilesByIds(selectedIds)
                        .subscribe(() => {
                            this.loading = true;
                            this.loadInvoices();
                            this.initGrid();
                            
                            this.toastr.success('Files are successfully created!');
                        },
                        error => {
                            this.toastr.error(error.error)
                        }).add(()=>{
                            this.modalService.hide(this.downloadTableAsExcelModal?.id);
                            this.downloadTableAsExcelModal = null;
                        })
            }
            )

        } else {
            this.toastr.error('This action can just be performed for invoices for which documents are not yet generated. Please adjust your selection.');
        }
    }

    sendInvoicesAsEmail()
    {
        const allInvoicesGenerated = this.selectedRows.every(row => row.statusId === InvoiceStatusEnum.Generated);
        if (allInvoicesGenerated) {
            const selectedIds = this.selectedRows.map(row => row.id);

            const initialState: ModalOptions = {
                backdrop: 'static',
                keyboard: false,
                initialState: {
                    title: "Send invoice as Email",
                    body: "The selected invoices will be sent to the configured recipients (please check them). Do you want to continue?",
                    okayButton: "YES",
                    cancelButton: "Cancel",
                }
            };
    
            const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);
    
            bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                const config = {
                    ignoreBackdropClick: true,
                    keyboard: false,
                    initialState: {
                      title: 'Sending invoice as email'
                    },
                  }
              
                this.downloadTableAsExcelModal = this.modalService.show(LoadingModalComponent, config);
        
                this.emailService.sendInvoicesAsEmail(selectedIds)
                    .subscribe(() => {
                        this.loading = true;
                            this.loadInvoices();
                            this.initGrid();
                        this.toastr.success('Emails are successfully sent!');
                    },
                    error => {
                        this.toastr.error(error.error)
                    }).add(()=>{
                        this.modalService.hide(this.downloadTableAsExcelModal?.id);
                        this.downloadTableAsExcelModal = null;
                    })
            }
            )
        }else{
            this.toastr.error('This action can just be performed for invoices for which documents are already generated and which are not yet sent. Please adjust your selection.');
        }
    }
}
