<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Address</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form" autocomplete="off">
        <div class="row mb-2">
            <div class="col-12">
                <label for="companyNameModal" class="form-label">{{'CompanyName' | transloco}}*</label>
                <input id="companyNameModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('companyName')" formControlName="companyName">
                <div class="invalid-feedback">Dies ist ein Pflichtfeld</div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-5">
                <label for="streetWithNumberModal" class="form-label">{{'StreetWithNumber' | transloco}}*</label>
                <input id="streetWithNumberModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('streetWithNumber')" formControlName="streetWithNumber">
                <div class="invalid-feedback">Dies ist ein Pflichtfeld</div>
            </div>
            <div class="col-5">
                <label for="postcodeAndCityModal" class="form-label">{{'PostcodeAndCity' | transloco}}*</label>
                <input id="postcodeAndCityModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('postcodeAndCity')" formControlName="postcodeAndCity">
                <div class="invalid-feedback">Dies ist ein Pflichtfeld</div>
            </div>
            <div class="col-2">
                <label for="countryModal" class="form-label">{{'Country' | transloco }}*</label>
                <input id="countryModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('country')" formControlName="country">
                <div class="invalid-feedback">Dies ist ein Pflichtfeld</div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>