import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/de-CH';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalGuard,
  MsalModule,
  MsalRedirectComponent
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { AgGridModule } from 'ag-grid-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BillingModule } from './billing/billing.module';
import { LinkItemComponent } from './navigation/side-navbar/link-item/link-item.component';
import { SideNavbarComponent } from './navigation/side-navbar/side-navbar.component';
import { TopNavbarComponent } from './navigation/top-navbar/top-navbar.component';
import { SecurityModule } from './security/security.module';
import { AuthInterceptor } from './security/services/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { PmtDatepickerModule } from '@premotec/pmt-datepicker';
import { RouterModule } from '@angular/router';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

registerLocaleData(localeDECH);

@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    SideNavbarComponent,
    LinkItemComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    SharedModule,
    SecurityModule,
    BillingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AgGridModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TranslocoRootModule,
    PmtDatepickerModule,

    //info about msal config: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/redirects.md
    //https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-angular-auth-code
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.msal.clientId,
          authority: `https://login.microsoftonline.com/${environment.msal.tenantId}`,
          redirectUri: environment.msal.redirectUrl,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
        system: {
          //tokenRenewalOffsetSeconds: 60,
          allowRedirectInIframe: true,
          loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['openid'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: undefined,
      }
    ),

  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-CH' },
    MsalGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  exports: [HttpClientModule, ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
