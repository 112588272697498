import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss']
})
export class DeleteConfirmationModalComponent extends BaseComponent {
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;

  title?: string;
  body?: string;
  okayButton?: string;
  cancelButton?: string;
  icon?: string;
  color: string = 'danger';
  
  showMessageBox = false;
  message = new UntypedFormControl('', Validators.required);
  submitted = false;

  constructor(public bsModalRef: BsModalRef) {
    super();
  }


  confirm() {
    if (this.showMessageBox === true && this.message.invalid) {
      this.submitted = true;
      return;
    }

    this.confirmed.emit(true);
    this.bsModalRef.hide();
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

}
