import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'app-ag-grid-footer',
  templateUrl: './ag-grid-footer.component.html',
  styleUrls: ['./ag-grid-footer.component.scss']
})
export class AgGridFooterComponent extends BaseComponent implements OnInit {

  filteredRowCount = 0;
  fullRowCount = 0;

  constructor(
    private gridService: GridService,
    private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.gridService.filteredRowCount$.subscribe((res) => {
      this.filteredRowCount = res;
      this.cd.detectChanges();
    });

    this.gridService.fullRowCount$.subscribe((res) => {
      this.fullRowCount = res;
      this.cd.detectChanges();
    });
  }

  erase(){
    this.gridService.eraseFilterAndSelection();
  }

  isLoading(): boolean{
    return this.gridService.isLoading;
  }

}
