import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BillingGroupEmailSetting } from 'src/app/billing/models/billing-group/billing-group-email-settings.model';
import { BillingGroup } from 'src/app/billing/models/billing-group/billing-group.model';

@Component({
  selector: 'app-update-email-settings-modal',
  templateUrl: './update-email-settings-modal.component.html',
  styleUrls: ['./update-email-settings-modal.component.scss']
})
export class UpdateEmailSettingsModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<BillingGroupEmailSetting>();

  form: UntypedFormGroup;
  billingGroup: BillingGroup

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService) { }


  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup() {
    this.form = this.fb.group({
      salutation: [this.billingGroup?.emailSalutation, Validators.required],
      recipientsArray: this.fb.array([]),
    })

    this.billingGroup?.emailRecipients.forEach((recipient: any) => {
      this.recipientsArray.push(this.fb.group({
        recipient: [recipient, [Validators.required, Validators.email]],
      }))
    });
  }

  get recipientsArray(): UntypedFormArray {
    return this.form.controls['recipientsArray'] as UntypedFormArray;
  }

  addItem() {
    const recipientFormGroup = this.fb.group({
      recipient: ['', Validators.required],
    });
    this.recipientsArray.push(recipientFormGroup);
  }

  removeItem(index: number) {
    this.recipientsArray.removeAt(index);
  }

  onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getInvoiceInformationDetails());
      this.bsModalRef.hide();
    }
    else {
      this.toastr.error("Es liegen noch Validierungsfehler vor!");
      this.form.markAllAsTouched();
    }
  }

  getInvoiceInformationDetails() {
    var x = [];
    this.form.controls['recipientsArray'].value.forEach(element => {
      x.push(element['recipient'])
    });

    let updatedEmailSettings: BillingGroupEmailSetting = {
      salutation: this.form.controls['salutation'].value,
      recipients: x
    };

    return updatedEmailSettings;
  }

  public close() {
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field)!.invalid && this.form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }
}
