import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { BillingGroupAddress } from 'src/app/billing/models/billing-group/billing-group-address.model';
import { BillingGroup } from 'src/app/billing/models/billing-group/billing-group.model';
import { BillingGroupService } from 'src/app/billing/services/billing-group.service';
import { UpdateAddressModalComponent } from './update-billing-group-address-modal/update-address-modal.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() billingGroup: BillingGroup;
  mapsUrl: string;

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private billingGroupService: BillingGroupService) { }

  ngOnInit(): void {
    this.mapsUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=${this.billingGroup?.addressLine1}${this.billingGroup?.addressLine2}&language=DE&maptype=satellite `;
  }

  onEditAddressData() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg',
      initialState: {
        billingGroup: this.billingGroup
      },
    };
    const bsModalRef = this.modalService.show(UpdateAddressModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe((updatedAddress: BillingGroupAddress) => {
        this.billingGroupService.updateBillingGroupAddress(this.billingGroup.id, updatedAddress).subscribe(
          x => {
            // mapping
            this.billingGroup.companyName = updatedAddress.companyName
            this.billingGroup.addressLine1 = updatedAddress.addressLine1
            this.billingGroup.addressLine2 = updatedAddress.addressLine2
            this.billingGroup.country = updatedAddress.country
            this.mapsUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=${updatedAddress.addressLine1}${updatedAddress.addressLine2}&language=DE&maptype=satellite`;

            this.toastr.success("Data successfully updated!")
          },
          err => {
            this.toastr.error(err.error, "An error occured!")
          })
      })
  }
}
