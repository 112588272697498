import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Customer } from "../models/customer/customer.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Project } from "../models/project/project.model";
import { Employee } from "../models/employee/employee.model";
import { EmployeeProject } from "../models/employee/employee-project.model";
import { EmployeeUpdate } from "../models/employee/employee-update.model";

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(private http: HttpClient) { }

    public getProjectEmployees(id: string): Observable<EmployeeProject[]> {
        return this.http.get<EmployeeProject[]>(`${environment.api}/project/${id}/employees`);
    }

    public getEmployeeEntities(id: string): Observable<EmployeeProject[]> {
        return this.http.get<EmployeeProject[]>(`${environment.api}/employee/${id}/entities`);
    }

    public getEmployees(): Observable<Employee[]> {
        return this.http.get<Employee[]>(`${environment.api}/employee`);
    }

    public getEmployeeById(id: string): Observable<Employee> {
        return this.http.get<Employee>(`${environment.api}/employee/${id}`);
    }

    public updateEmployee(id: string, employeeUpdate: EmployeeUpdate): Observable<EmployeeUpdate> {
        return this.http.put<EmployeeUpdate>(`${environment.api}/employee/${id}`, employeeUpdate);
    }

}