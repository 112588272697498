import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { InvoicePreview } from 'src/app/billing/models/invoice/invoice-preview.model';
import { InvoicePosition } from 'src/app/billing/models/invoice/invoice-position.model';
import { InvoiceService } from 'src/app/billing/services/invoice.service';
import { InvoiceByDates } from 'src/app/billing/models/invoice/invoice-by-dates.model';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-invoice-summary',
	templateUrl: './invoice-summary.component.html',
	styleUrls: ['./invoice-summary.component.scss']
})
export class InvoiceSummaryComponent implements OnInit {

	public loading: boolean;
	public startDate: Date;
	public endDate: Date;

	public lastQuarter: number;
	public currentQuarter: number;
	public nextQuarter: number;

	public currentQuarterYear: number;
	public lastQuarterYear: number;
	public nextQuarterYear: number;

	public invoices: InvoicePreview[];

	public grandTotal: number = 0;

	constructor(private route: ActivatedRoute,
        public location: Location,
        private fb: FormBuilder,
		private toastr: ToastrService,
		private invoiceService: InvoiceService,
    ) {
		this.initDefaultDates();
	}

	ngOnInit(): void {
		this.setQuarters();
	}

	getShortDate(date: Date): string {
		const dd = this.fillZero(date.getDate());
		const mm = this.fillZero(date.getMonth() + 1);
		const yyyy = date.getFullYear();
		return `${yyyy}-${mm}-${dd}`;
	}

	fillZero(number: number): string {
		return number < 10 ? '0' + number : number.toString();
	}

	initDefaultDates(): void {
		const now = new Date();
		this.startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		this.endDate = new Date(now.getFullYear(), now.getMonth(), 0);
	}

	setLastMonthDates(): void {
		this.initDefaultDates();
	}

	setCurrentMonthDates(): void {
		const now = new Date();
		this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
		this.endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
	}

	setCurrentQuarterDates(): void {
		this.setQuarterDates(new Date(), 0);
	}

	setLastQuarterDates(): void {
		const now = new Date();
		this.setQuarterDates(now, -1);
	}

	setNextQuarterDates(): void {
		const now = new Date();
		this.setQuarterDates(now, 1);
	}

	setQuarterDates(date: Date, offset: number): void {
		const quarter = Math.floor((date.getMonth() / 3) + offset) % 4;
		const year = date.getFullYear() + Math.floor((date.getMonth() / 3 + offset) / 4);
		this.startDate = new Date(year, quarter * 3, 1);
		this.endDate = new Date(year, quarter * 3 + 3, 0);
	}

	setQuarters(): void {
		const now = new Date();
		this.currentQuarter = this.getQuarter(now);
		this.currentQuarterYear = now.getFullYear();

		const lastQuarterEndDate = new Date(now);
		lastQuarterEndDate.setMonth(now.getMonth() - 3);
		this.lastQuarter = this.getQuarter(lastQuarterEndDate);
		this.lastQuarterYear = lastQuarterEndDate.getFullYear();

		const nextQuarterStartDate = new Date(now);
		nextQuarterStartDate.setMonth(now.getMonth() + 3);
		this.nextQuarter = this.getQuarter(nextQuarterStartDate);
		this.nextQuarterYear = nextQuarterStartDate.getFullYear();
	}

	getQuarter(date: Date): number {
		return Math.floor(date.getMonth() / 3);
	}

	setCurrentWeekDates(): void {
		this.setWeekDates(0);
	}

	setLastWeekDates(): void {
		this.setWeekDates(-1);
	}

	setWeekBeforeLastDates(): void {
		this.setWeekDates(-2);
	}

	setWeekDates(weekOffset: number): void {
		const today = new Date();
		const start = this.startOfWeek(this.addDays(today, weekOffset * 7), 1);
		this.startDate = start;
		this.endDate = this.addDays(start, 6);
	}

	startOfWeek(date: Date, weekStartsOn: number): Date {
		const diff = (date.getDay() < weekStartsOn ? 7 : 0) + date.getDay() - weekStartsOn;
		const start = new Date(date);
		start.setDate(date.getDate() - diff);
		start.setHours(0, 0, 0, 0);
		return start;
	}

	addDays(date: Date, days: number): Date {
		const result = new Date(date);
		result.setDate(date.getDate() + days);
		return result;
	}

	sortPositions(id: string){
        let invoice = this.invoices.find(i => i.id === id);

        let positions: InvoicePosition[] = [];
        let isInvoicePositionsSorted = false;
            //check if position is already sorted
            if (invoice.positions.length > 1 && !(invoice.positions[0].sort === 0 && invoice.positions[1].sort === 0)) {
                isInvoicePositionsSorted = true
            }
            for (let i = 0; i < invoice.positions.length; i++) {
                if (!isInvoicePositionsSorted) {
                    invoice.positions[i].sort = i
                }
                positions.push(invoice.positions[i]);
            }
			invoice.positions = positions;
    }

	sortBy(prop: string, id: string) {
        let invoice = this.invoices.find(i => i.id === id);
        return invoice.positions.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

	loadInvoices(){
		let invoiceByDates: InvoiceByDates = {
			startDate: this.startDate,
			endDate: this.endDate
		};

		this.grandTotal = 0;

		this.invoiceService.getInvoiceSummary(invoiceByDates).subscribe(x => {
			this.invoices = x.sort((a, b) => a.billingGroupName.localeCompare(b.billingGroupName));

			this.invoices.forEach(invoice => {
				this.sortPositions(invoice.id);
				this.calculatePricesForInvoice(invoice.id);
			})

			this.loading = false;
		},
		(err) => {
			this.toastr.error('An error occurred while loading invoice summary!')
			this.loading = false;
		})
	}

	calculatePricesForInvoice(id: string) {
        let invoice = this.invoices.find(i => i.id === id);
        if (invoice) {
            invoice.invoiceTotalPrice = 0;
            invoice.invoiceTotalVat = 0;
            invoice.invoiceTotalPriceVat = 0;
    
            invoice.positions.forEach(x => {
                invoice.invoiceTotalPrice += x.amount * x.price;
                invoice.invoiceTotalVat += (x.amount * x.price) / 100 * x.vatPct
            });
            invoice.invoiceTotalPriceVat = invoice.invoiceTotalPrice + invoice.invoiceTotalVat;
			
			this.grandTotal += invoice.invoiceTotalPrice;
        }
    }
}
