import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InvoicePositionUpdate } from 'src/app/billing/models/invoice/invoice-position-update.model';

@Component({
  selector: 'app-createOrUpdate-position-modal',
  templateUrl: './createOrUpdate-position-modal.component.html',
  styleUrls: ['./createOrUpdate-position-modal.component.scss']
})
export class CreateOrUpdatePositionModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<InvoicePositionUpdate>();

  form = new UntypedFormGroup({});

  invoicePosition;
  vatDefault: string;
  createOrUpdateMode: string;

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.form = this.fb.group({
      description: [null, Validators.required],
      vatPct: [this.vatDefault, Validators.required],
      amount: [null, Validators.required],
      price: [null, Validators.required],
    });

    if (this.createOrUpdateMode == 'update') {
      this.form.controls['description'].setValue(this.invoicePosition.description);
      this.form.controls['vatPct'].setValue(this.invoicePosition.vatPct);
      this.form.controls['amount'].setValue(this.invoicePosition.amount);
      this.form.controls['price'].setValue(this.invoicePosition.price);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getInvoicePosition());
      this.close();
    }
    else {
      this.toastr.error('This form has validation errors!', '');
      this.form.markAllAsTouched();
    }
  }

  getInvoicePosition() {
    let invoidePositionData: InvoicePositionUpdate = {
      description: this.form.controls['description'].value,
      vatPct: this.form.controls['vatPct'].value,
      amount: this.form.controls['amount'].value,
      price: this.form.controls['price'].value,
    };
    return invoidePositionData;
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return 'is-invalid'
    }
    return '';
  }

  public close() {
    this.bsModalRef.hide();
  }

}
