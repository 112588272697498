import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { BehaviorSubject } from "rxjs";


// Remove provided in root when not use as singleton
@Injectable(
    //   {
    //     providedIn: 'root'
    // }
)
export class GridService {
    grid: AgGridAngular;
    private rows: any[];
    gridLocation: string;
    selectedNodes: any[];
    gridEventListenerIsSet: boolean = false;

    fullRowCount$: BehaviorSubject<number> = new BehaviorSubject(0);
    filteredRowCount$: BehaviorSubject<number> = new BehaviorSubject(0);

    localStorageKeyName: string;

    public isLoading: boolean;

    constructor(private router: Router) {
    }

    eraseFilterAndSelection() {
        this.grid?.api?.setFilterModel(null);
        this.grid?.api?.deselectAll();
    }

    /** 
     * Filter to LocalStorage // Count filtered Rows // Update on Filterchange
     * @param agGrid >`'@ViewChild('agGrid') agGrid: AgGridAngular;` 
     * @param rows > Your Data as an Array
     * @param gridLocation >`'this.constructor.name'`
     * @param selectedNodes >(optional) The currently selected data     */
    processGrid(agGrid: AgGridAngular, rows: any[], gridLocation: string, selectedNodes?: any[], ) {
        this.grid = agGrid;
        this.rows = rows;
        this.selectedNodes = selectedNodes;
        this.gridLocation = gridLocation;
        
        this.localStorageKeyName = "AGGrid" + this.router.url + "/" + this.gridLocation;

        this.updateData();

        this.getOrSetFilterValueFromOrToLocalStorage();
    }

    private updateData() {
        const filterModel = this.grid.api.getFilterModel();
        const selectedId = this.grid.api.getSelectedNodes().map(node => node.data.id);
        this.selectedNodes = [];

        this.grid.api.setRowData(this.rows);

        this.grid.api.forEachNode(node => {
            if (selectedId.indexOf(node.data.id) !== -1) node.setSelected(true);
        })

        this.grid.api.setFilterModel(filterModel);

        this.setCounters();
        this.isLoading = false;
    }

    private getOrSetFilterValueFromOrToLocalStorage() {
        if (!this.gridEventListenerIsSet) {
            this.gridEventListenerIsSet = true;
            // setTimeout with 1ms is because of an asynchronous bug.
            // This prevents to get into this method on initial loading the component and set a new Date, which would be wrong.
            setTimeout(() => {
                // Store Filter in LocalStorage
                this.grid.api.addEventListener('filterChanged', (e) => {
                    const filterItem = {
                        value: this.grid.api.getFilterModel(),
                        createDate: new Date()
                    }
                    if (Object.keys(filterItem.value).length === 0) {
                        localStorage.removeItem(this.localStorageKeyName);
                    }
                    else {
                        var filterAsJson = JSON.stringify(filterItem)
                        localStorage.setItem(this.localStorageKeyName, filterAsJson);
                    }
                    this.setCounters();
                })
            }, 1)
        }

        // Get Filter from LocalStorage
        if (localStorage.getItem(this.localStorageKeyName)) {
            var localStorageData = JSON.parse(localStorage.getItem(this.localStorageKeyName));
            this.grid.api.setFilterModel(localStorageData.value);
            this.setCounters();
        }
    }

    getFullRowCount() {
        if (this.rows == null) return 0;
        return this.rows.length;
    }

    getFilteredRowCount() {
        if (this.grid?.api == null) return 0;
        return this.grid.api.getModel().getTopLevelRowCount();
    }
    setCounters() {
        this.filteredRowCount$.next(this.grid.api.getModel().getTopLevelRowCount());
        this.fullRowCount$.next(this.rows.length);
    }
}
