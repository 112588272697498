import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ag-grid-customer-action-buttons',
  templateUrl: './ag-grid-customer-action-buttons.component.html',
  styleUrls: ['./ag-grid-customer-action-buttons.component.scss']
})
export class AgGridCustomerActionButtonsComponent 
implements ICellRendererAngularComp {
  bsModalRef?: BsModalRef;
  params: ICellRendererParams;
  value: string;
  gridApi: GridApi;
  gridData: any;

  constructor(
    private router: Router
  ) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.gridApi = params.api;
  }

  refresh() {
    return false;
  }
}
