import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { BillingGroupCreate } from 'src/app/billing/models/billing-group/billing-group-create.model';
import { CurrencyEnum } from 'src/app/billing/models/enums/currency.enum';
import { Bank } from 'src/app/billing/models/invoice/bank.model';
import { BankService } from 'src/app/billing/services/bank.service';
import { BillingGroupService } from 'src/app/billing/services/billing-group.service';
import { LanguageService } from 'src/app/billing/services/language.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-billing-group-create',
  templateUrl: './billing-group-create.component.html',
  styleUrls: ['./billing-group-create.component.scss']
})
export class BillingGroupCreateComponent implements OnInit {

  formNameAndStatus: UntypedFormGroup;
  formAddressData: UntypedFormGroup;
  formInvoiceInformation: UntypedFormGroup;
  formEmailSettings: UntypedFormGroup

  bsModalRef: BsModalRef;

  tempActiveState: boolean = true;

  languages: any[];
  banks: Bank[];
  CurrencyEnum = CurrencyEnum;


  private stepper: Stepper;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private bankService: BankService,
    private languageService: LanguageService,
    private billingGroupService: BillingGroupService,
    private toastr: ToastrService,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    this.stepper = new Stepper(document.querySelector('#stepperForm'), { linear: false, animation: true })

    this.getDropdownValues();
    this.initFormGroup();

    this.formNameAndStatus.controls['isActive'].valueChanges.subscribe(x => this.tempActiveState = !this.tempActiveState);
  }

  getDropdownValues() {
    this.bankService.getAllBanks().subscribe((banks: Bank[]) => {
      this.banks = banks;
    });

    this.languageService.getAllLanguages().subscribe((languages) => {
      this.languages = Object.entries(languages);
    });
  }

  initFormGroup() {
    this.formNameAndStatus = this.fb.group({
      billingGroupName: [null, Validators.required],
      isActive: [true, Validators.required],
    });

    this.formAddressData = this.fb.group({
      companyName: [null, Validators.required],
      streetWithNumber: [null, Validators.required],
      postcodeAndCity: [null, Validators.required],
      country: ['CH', Validators.required]
    });

    this.formInvoiceInformation = this.fb.group({
      customerOrderNumber: [null],
      bank: [null, Validators.required],
      folderName: [null],
      language: [null, Validators.required],
      connectedWithWorktimes: [false, Validators.required],
      internalBilling: [false, Validators.required]
    });

    this.formEmailSettings = this.fb.group({
      salutation: [null, Validators.required],
      recipientsArray: this.fb.array([]),
    });

    this.addItem()
  }

  get recipientsArray(): UntypedFormArray {
    return this.formEmailSettings.controls['recipientsArray'] as UntypedFormArray;
  }

  addItem() {
    const recipientFormGroup = this.fb.group({
      recipient: ['', [Validators.required, Validators.email]],
    });
    this.recipientsArray.push(recipientFormGroup);
  }

  removeItem(index: number) {
    this.recipientsArray.removeAt(index);
  }

  submitNameAndStatus() {
    if (this.formNameAndStatus.valid) {
      this.stepper.next();
    }
    else {
      this.formNameAndStatus.markAllAsTouched();
    }
  }

  submitAddressData() {
    if (this.formAddressData.valid) {
      this.stepper.next();
    }
    else {
      this.formAddressData.markAllAsTouched();
    }
  }

  submitInvoiceInformation() {
    if (this.formInvoiceInformation.valid) {
      this.stepper.next();
    }
    else {
      this.formInvoiceInformation.markAllAsTouched();
    }
  }

  submitEverything() {
    if (this.formEmailSettings.valid) {
      var data = this.getDataOfAllForms();
      this.saveToDatabase(data)
    }
    else {
      this.formEmailSettings.markAllAsTouched();
    }
  }

  getDataOfAllForms() {
    var x = [];
    this.formEmailSettings.controls['recipientsArray'].value.forEach(element => {
      x.push(element['recipient'])
    });

    let createdBillingGroup: BillingGroupCreate = {
      name: this.formNameAndStatus.controls['billingGroupName'].value,
      isActive: this.formNameAndStatus.controls['isActive'].value,
      companyName: this.formAddressData.controls['companyName'].value,
      addressLine1: this.formAddressData.controls['streetWithNumber'].value,
      addressLine2: this.formAddressData.controls['postcodeAndCity'].value,
      country: this.formAddressData.controls['country'].value,
      customerOrderNumber: this.formInvoiceInformation.controls['customerOrderNumber'].value,
      language: this.formInvoiceInformation.controls['language'].value,
      bankId: this.formInvoiceInformation.controls['bank'].value,
      folderName: this.formInvoiceInformation.controls['folderName'].value,
      connectedWithWorktimes: this.formInvoiceInformation.controls['connectedWithWorktimes'].value,
      internalBilling: this.formInvoiceInformation.controls['internalBilling'].value,
      emailSetting: {
        salutation: this.formEmailSettings.controls['salutation'].value,
        recipients: x
      }
    };

    return createdBillingGroup;
  }

  saveToDatabase(createdBillingGroup: BillingGroupCreate) {
    this.billingGroupService.createBillingGroup(createdBillingGroup)
      .subscribe(
        x => {
          this.toastr.success(`The Billing group <b>${createdBillingGroup.name}</b> was successfully created and saved!`, '', { enableHtml: true })
          this.router.navigateByUrl(`/billing/billing-group/${x}`)
        },
        err => {
          this.toastr.error("An error occured while creating the Billing group", err)
        })
  }

  goBack() {
    this.stepper.previous();
  }

  cancel() {
    const initialState: ModalOptions = {
      initialState: {
        body: 'You really want to leave? <br> The entered data will be lost!',
      },
    };
    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('/billing/billing-group');
      })
  }

  /* Add CSS if field is invalid */
  validateField(field: string, form) {
    if (form.get(field)!.invalid && form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }

}
