<div class="card mt-2">
    <h4 class="card-header d-flex justify-content-between align-items-center">
        <div><i class="fa-solid fa-file-invoice-dollar fa-sm"></i> {{'InvoiceInformation' | transloco}}</div>
        <button type="button" class="btn btn-sm btn-primary" (click)="onEditInvoiceInformation()">
            <i class="fas fa-edit" tooltip="{{ 'edit' | transloco}}"></i>
        </button>
    </h4>
    <div class="card-body">
        <div class="row mb-2">
            <div class="col-7">
                <label for="customerOrderNumber" class="form-label">{{'CustomerOrderNumber' | transloco}}</label>
                <input id="customerOrderNumber" type="text" class="form-control inlineInput" disabled [value]="billingGroup?.customerOrderNumber">
            </div>
            <div class="col-5">
                <label for="language" class="form-label">{{'Language' | transloco }}</label>
                <input id="language" type="text" class="form-control inlineInput" disabled [value]="(billingGroup?.language | uppercase)">
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <label for="bank" class="form-label">{{'BankAccount' | transloco}}</label>
                <input id="bank" type="text" class="form-control inlineInput" disabled [value]="(billingGroup.bank.name + ' - ' + (CurrencyEnum[billingGroup.bank.currency] | uppercase ))">
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <label for="foldername" class="form-label">{{'FolderName' | transloco}}</label>
                <input id="foldername" type="text" class="form-control inlineInput" disabled [value]="billingGroup.folderName">
                <small class="form-text text-muted">{{ 'ForDownload' | transloco }}</small>
            </div>
        </div>
        <div class="mb-2 mt-3">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="connectedWithWorktimes"
                [checked]="billingGroup?.connectedWithWorktimes" disabled>
                <label for="connectedWithWorktimes">Connect with worktimes</label>
            </div>
        </div> 
        <div class="mb-2 mt-3">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="internalBilling"
                [checked]="billingGroup?.internalBilling" disabled>
                <label for="internalBilling">Internal billing</label>
            </div>
        </div> 
    </div>
</div>