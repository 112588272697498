import { EmployeeProject } from "../employee/employee-project.model";
import { ProjectActivity } from "./project-activity.model";

export class Project {
    id: string;
    name: string;
    projectReference: string;
    isActive: boolean;
    hourlyRate: number;
    projectActivities: ProjectActivity[];
    billingGroupId?: string;
    projectEmployees: EmployeeProject[];
}