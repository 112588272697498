<div class="card mt-2">
    <h4 class="card-header d-flex justify-content-between align-items-center">
        <div><i class="fa-solid fa-map-location-dot fa-sm"></i> {{'Adresse' | transloco}}</div>
        <button type="button" class="btn btn-sm btn-primary" (click)="onEditAddressData()">
            <i class="fas fa-edit" tooltip="{{ 'edit' | transloco}}"></i>
        </button>
    </h4>
    <div class="card-body">
        <div class="row mb-2">
            <div class="col-8">
                <label for="companyName" class="form-label">{{'CompanyName' | transloco}}</label>
                <input id="companyName" type="text" class="form-control inlineInput" disabled [value]="billingGroup?.companyName">
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-5">
                <label for="streetWithNumber" class="form-label">{{'StreetWithNumber' | transloco}}</label>
                <input id="streetWithNumber" type="text" class="form-control inlineInput" disabled [value]="billingGroup?.addressLine1">
            </div>
            <div class="col-5">
                <label for="postcodeAndCity" class="form-label">{{'PostcodeAndCity' | transloco}}</label>
                <input id="postcodeAndCity" type="text" class="form-control inlineInput" disabled [value]="billingGroup?.addressLine2">
            </div>
            <div class="col-2">
                <label for="country" class="form-label">{{'Country' | transloco }}</label>
                <input id="country" type="text" class="form-control inlineInput" disabled [value]="billingGroup?.country">
            </div>
        </div>
        <div class="map-responsive">
            <iframe
                [src]="mapsUrl | safe"
                width="100%"
                height="300"
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    </div>
</div>