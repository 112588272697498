import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent  extends BaseComponent implements OnInit {

  @Output() isCollapsed = new EventEmitter<boolean>();

  collapsed: boolean = false;
  textCollapsed: boolean = false;

  constructor() {super(); }

  ngOnInit(): void {
  }

  toggleNavbar() {
    this.collapsed = !this.collapsed
    this.isCollapsed.emit(!this.collapsed);
    if(this.textCollapsed){
      setTimeout(() => {
        this.textCollapsed = false;
      }, 300)
    }
    else{
      this.textCollapsed = true;
    }
  }

}
