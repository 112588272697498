import { Injectable } from '@angular/core';
import { AutoFocusFixDirective } from '@premotec/ngx-essentials';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridEmployeeActionButtonsComponent } from './ag-grid-employee-action-buttons/ag-grid-employee-action-buttons.component';

@Injectable({
  providedIn: "root"
})
export class EmployeeGridColumnService {
  constructor() { }
  async getColumns(): Promise<(ColDef | ColGroupDef)[]> {

    const cols: ColDef[] = [
        {
          field: 'firstName',
          headerName: 'Firstname',
          sort: 'asc',
          width:480
        },
        {
            field: 'lastName',
            headerName: 'Lastname',
            width:480
        },
        {
            field: 'isActive',
            headerName: 'Active',
            filter: true,            
            minWidth: 120
        },
        {
            field: 'editButton',
            headerName: '',
            filter: false,
            sortable: false,
            cellRenderer: AgGridEmployeeActionButtonsComponent,
            cellEditorPopupPosition: 'under',
            tooltipField: "edit",
            minWidth: 75, 
            maxWidth: 75, 
            pinned: 'right'
        },
    ]

    return cols
  }

}