import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Bank } from '../models/invoice/bank.model';

@Injectable({
  providedIn: 'root'
})

export class BankService {
  constructor(private http: HttpClient) {}

  public getAllBanks(): Observable<Bank[]> {
    return this.http.get<Bank[]>(`${environment.api}/Bank`);
  }
}
