import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectService } from 'src/app/billing/services/project.service';
import { InvoiceByDates } from 'src/app/billing/models/invoice/invoice-by-dates.model';

@Component({
  selector: 'app-create-invoice-preview-modal',
  templateUrl: './create-invoice-preview-modal.component.html',
  styleUrls: ['./create-invoice-preview-modal.component.scss']
})
export class CreateInvoicePreviewModalComponent extends BaseComponent implements OnInit {
  
  @Output() confirmed = new EventEmitter<InvoiceByDates>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;

  //Modal variables

  title?: string;
  body?: string;
  okayButton?: string;
  cancelButton?: string;

  showMessageBox = false;
  message = new UntypedFormControl('', Validators.required);
  submitted = false;

  //Formular variable

  public invoicePreview: InvoiceByDates;

  public startDate: Date;
  public endDate: Date;

  public loading: boolean;

  public lastQuarter: number;
  public currentQuarter: number;
  public nextQuarter: number;

  public currentQuarterYear: number;
  public lastQuarterYear: number;
  public nextQuarterYear: number;

  public rangeWithIncompleteProjects: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private projectService: ProjectService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initDefaultDates();
    this.setQuarters();
    this.loading = true;
  }

  //Modal functions

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

  //Formular Functions

  initDefaultDates() {
    const dateNow = new Date();
    this.startDate = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1);
    this.endDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 0);
  }

  createInvoicesFromWorktime() {
    this.invoicePreview = new InvoiceByDates();
    this.invoicePreview.startDate = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()));
    this.invoicePreview.endDate = this.endDate;

    this.confirmed.emit(this.invoicePreview);
    this.bsModalRef.hide();
  }

  checkIfProjectsAreIncomplete(){
    this.projectService.IsAnyIncompleteProject(this.getShortDate(this.startDate), this.getShortDate(this.endDate)).subscribe((res)=>{
    if(res){
        this.rangeWithIncompleteProjects = true;
    } else {
        this.rangeWithIncompleteProjects = false;
    }
    })
  }

  setLastMonthDates() {
    this.initDefaultDates();
    this.checkIfProjectsAreIncomplete();
  }

  setCurrentMonthDates() {
    const dateNow = new Date();
    this.startDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.endDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
    this.checkIfProjectsAreIncomplete();
  }

  setCurrentQuarterDates() {
    const dateNow = new Date();
    this.currentQuarter = Math.floor(dateNow.getMonth() / 3);
    this.startDate = new Date(dateNow.getFullYear(), this.currentQuarter * 3, 1);
    this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 3, 0);
    this.checkIfProjectsAreIncomplete();
  }

  setLastQuarterDates() {
    const dateNow = new Date();
    const lastQuarterEndDate = this.getStartDate(dateNow);
    lastQuarterEndDate.setDate(lastQuarterEndDate.getDate() - 1);
    this.lastQuarter = this.getQuarter(lastQuarterEndDate);
    this.startDate = this.getStartDate(lastQuarterEndDate);
    this.endDate = lastQuarterEndDate;
    this.checkIfProjectsAreIncomplete();
  }

  setNextQuarterDates() {
    const dateNow = new Date();
    const nextQuarterStartDate = this.getEndDate(this.getStartDate(dateNow));
    nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() + 1);

    this.startDate = nextQuarterStartDate;
    this.endDate = this.getEndDate(this.startDate);
    this.checkIfProjectsAreIncomplete();
  }

  setQuarters() {

    const dateNow = new Date();

    this.currentQuarter = (this.getQuarter(dateNow));
    this.currentQuarterYear = dateNow.getFullYear();

    const lastQuarterEndDate = this.getStartDate(dateNow);
    lastQuarterEndDate.setDate(lastQuarterEndDate.getDate() - 1);
    this.lastQuarter = (this.getQuarter(lastQuarterEndDate));
    this.lastQuarterYear = lastQuarterEndDate.getFullYear();

    const nextQuarterStartDate = this.getEndDate(this.getStartDate(dateNow));
    nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() + 1);
    this.nextQuarter = (this.getQuarter(nextQuarterStartDate));
    this.nextQuarterYear = nextQuarterStartDate.getFullYear();

  }

  getQuarter(date: Date) {
    return Math.floor(date.getMonth() / 3);
  }

  getStartDate(date: Date) {
    const quarter = this.getQuarter(date);
    return new Date(date.getFullYear(), quarter * 3, 1);
  }

  getEndDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 3, 0);
  }

    getShortDate(date: Date) {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        return  yyyy + '-' + this.fillZero(mm) + '-' + this.fillZero(dd);
    }

    fillZero(number: number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

  close(){
    this.bsModalRef.hide();
  }

}
