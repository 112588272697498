<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">
        <div class="card mb-3">
            <div class="card-header sticky-toolbar">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row row-datepicker">
                                <div class="col-lg-4">
                                    <div class="picker-width-fixed">
                                        <label for="startDate">Start date</label>
                                        <pmt-datepicker [(ngModel)]="startDate" [id]="'startDate'"></pmt-datepicker>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="picker-width-fixed">
                                        <label for="endDate">End date</label>
                                        <pmt-datepicker [(ngModel)]="endDate" [id]="'endDate'"></pmt-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col buttons-h-list">
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setLastMonthDates()">Last month</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentMonthDates()">Current month</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setLastQuarterDates()">Q{{this.lastQuarter + 1}} {{this.lastQuarterYear}}</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentQuarterDates()">Q{{this.currentQuarter + 1}} {{this.currentQuarterYear}}</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setNextQuarterDates()">Q{{this.nextQuarter + 1}} {{this.nextQuarterYear}}</button>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col buttons-h-list">
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentWeekDates()">Current week</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setLastWeekDates()">Last week</button>
                                    <button class="btn btn-light border me-2 btn-sm" (click)="setWeekBeforeLastDates()">Week before last</button>
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col">
                                    <button type="button" class="btn btn-primary" (click)="loadInvoices()">
                                        <i class="fas fa-search"></i> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-9">
            <div *ngFor="let invoice of invoices">
                <div class="card mb-3">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                          <h4>{{invoice.billingGroupName}}</h4>
                          <h4>{{invoice.invoiceTotalPrice | currencyCustomPipe}}</h4>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="invoice.positions && invoice.positions.length > 0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Project name</th>
                                    <th>Amount</th>
                                    <th>Price</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let position of sortBy('sort', invoice.id); let i = index">
                                    <td class="col-4">{{position.description}}</td>
                                    <td class="col-2">{{position.amount | currencyCustomPipe}}</td>
                                    <td class="col-2">{{position.price | currencyCustomPipe}}</td>
                                    <td class="col-2">{{position.amount * position.price | currencyCustomPipe}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-9" *ngIf="invoices">
            <div class="card mb-3">
                <div class="card-header">
                    <div class="d-flex justify-content-between">
                        <h4>Grand total excl. VAT</h4>
                        <h4>{{grandTotal | currencyCustomPipe}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>