<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Email Settings</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form" autocomplete="off">
        <div class="row mb-2">
            <div class="col-12">
                <label for="salutationModal" class="form-label">{{'Salutation' | transloco}}*</label>
                <textarea id="salutationModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('salutation')" formControlName="salutation" rows="3"></textarea>
                    <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <label for="recipientsModal" class="form-label">{{'Recipients' | transloco}}*</label>
                    </div>
                    <div class="card-body">
                        <ng-container formArrayName="recipientsArray">
                            <ng-container *ngFor="let recipientFormGroup of recipientsArray.controls; let i = index;">
                                <div class="input-group mb-1" [formGroupName]="i">
                                    <span class="input-group-text" id="basic-addon1">{{i+1}}</span>
                                    <input formControlName="recipient" class="form-control" type="text" [ngClass]="{'is-invalid': !recipientFormGroup.get('recipient')?.valid && recipientFormGroup.get('recipient').touched}" placeholder="{{'email' | transloco}}">
                                    <div class="invalid-feedback" *ngIf="recipientFormGroup.get('recipient').getError('required') && recipientFormGroup.get('recipient').touched">{{ 'validationMsg.required' | transloco }}.</div>
                                    <div class="invalid-feedback" *ngIf="recipientFormGroup.get('recipient').getError('email') && recipientFormGroup.get('recipient').touched">{{ 'validationMsg.emailNotValid' | transloco }}.</div>
                                    <button [hidden]="!recipientFormGroup.get('recipient')?.valid && recipientFormGroup.get('recipient').touched" type="button" class="btn btn-danger btn-sm" (click)="removeItem(i)"><i class="fa fa-trash"></i></button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <button type="button" class="btn btn-primary btn-sm float-end" (click)="addItem()"><i class="fa fa-plus"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>