
<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Employee</h4>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">

            <div class="form-group col">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Project</th>
                            <th>Hourly rate employees</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="projectEmployees">
                        <tr *ngFor="let employeeProject of projectEmployees.controls; let index = index" [formGroupName]="index">
                            <td>
                                <p>{{employeeProject.value.customerName}}</p>
                            </td>
                            <td>
                                <p>{{employeeProject.value.projectName}}</p>
                            </td>
                            <td>
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1">CHF</span>
                                    <input type="text" class="form-control" formControlName="hourlyRate"/> 
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
    <div class="modal-footer bg-dark text-light">
        <button type="button" class="btn btn-light" (click)="decline()">Cancel</button>
        <button type="submit" form="form" class="btn btn-success" (click)="onSubmit()"><i class="fas fa-save"></i> Save</button>
    </div>
</form>

