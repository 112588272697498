<li class="mb-2 cursor-pointer">
    <a 
      [routerLink]="routerLinkInput"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{exact:true}"
      class="nav-link text-white" 
      [tooltip]="collapsed ? (translocoText | transloco) : ''" 
      placement="bottom">
        <i class="{{iconCode}}"> </i> 
        <span [ngStyle]="{'margin-left': textCollapsed ? '0rem' : '0.6rem'}" *ngIf="!textCollapsed">{{ translocoText | transloco }}</span>
    </a>
  </li>