import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LastTimeTrackerImportDates } from '../models/import/lastTimeTrackerImportDates.model';


@Injectable({
    providedIn: 'root'
})
export class ImportService {

    constructor(private http: HttpClient) { }

    public getLastImportDate(): Observable<LastTimeTrackerImportDates> {
        return this.http.get<LastTimeTrackerImportDates>(`${environment.api}/import/lastTimeTrackerImportDates`);
    }

    public triggerImport(startDate: Date, endDate: Date): Observable<LastTimeTrackerImportDates> {
        return this.http.post<LastTimeTrackerImportDates>(`${environment.api}/import`, { startDate: startDate, endDate: endDate });
    }
}