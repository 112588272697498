<div class="text-center">
  <h4 class="mt-3">
    <i class="fa-2x text-{{color}}" [ngClass]="icon ? icon : 'fas fa-circle-exclamation'"></i>
  </h4>

  <div class="my-3">
    <h5 *ngIf="title">{{title}}</h5>
    <div [innerHtml]="body" *ngIf="body"></div>
    <div *ngIf="!body">{{'body'}}</div>
  </div>
  <div class="mb-3">
    <div class="btn btn-light border me-1" (click)="decline()">{{cancelButton || 'Cancel'}}</div>
    <div class="btn btn-{{color}} me-1" (click)="confirm()">{{okayButton || 'OK'}}</div>
  </div>
</div>