import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TranslocoService,
  provideTransloco
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslocoHttpLoader } from './transloco-http-loader.service';
import { customFallbackStrategy } from './fallback-strategy';

const availableLangs = ['de', 'en'];

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTransloco({
      config: {
        availableLangs: availableLangs,
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    }),
    customFallbackStrategy,
  ]
})
export class TranslocoRootModule {
  constructor(private translocoService: TranslocoService) {

    const userLang = navigator.language;

    if (userLang != null) {
      if (availableLangs.indexOf(userLang) !== -1) {
        this.translocoService.setActiveLang(userLang);
      }
    }

    this.translocoService.langChanges$.subscribe(res => {
      document.documentElement.setAttribute('lang', res);
    });
  }
}
