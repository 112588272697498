<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">
        <div class="card sticky-toolbar mb-3">
            <div class="card-header">
                <div class="form-group">
                    <div class="btn-group me-2">
                        <button routerLink="create" type="button" class="btn btn-primary">
                            <i class="fas fa-user-plus"></i>
                            Billing group
                        </button>
                    </div>
                    <div class="switch-container btn-group me-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="billingGroupOverviewActiveSwitch" type="checkbox" role="switch" [formControl]="activeSwitch"
                                unchecked>
                        </div>
                        <label for="billingGroupOverviewActiveSwitch" class="form-check-label">Show Inactive</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex pb-2">
            <h2 class="pe-3">Billing groups</h2>
        </div>
    
        <ag-grid-angular 
            #agGrid
            class="ag-theme-alpine" 
            style="width: 100%; height: calc(100vh - 250px);"
            *ngIf="gridConfigReady" 
            [gridOptions]="gridOptions" 
            (gridReady)="onGridReady($event)"
            [rowData]="billingGroups" 
            [defaultColDef]="defaultColDef"
            enableCellTextSelection="true">
        </ag-grid-angular>
        <app-ag-grid-footer></app-ag-grid-footer>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>