import { Pipe } from '@angular/core';

@Pipe({ name: 'currencyCustomPipe' })
export class CurrencyCustomPipe {

    transform(value: string | number): string {
        if(typeof value === 'string'){
            value = +value;
        }
        let newStr = value.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'");
        return newStr;
    }

}