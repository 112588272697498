import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ag-grid-edit-button',
  templateUrl: './ag-grid-edit-button.component.html',
  styleUrls: []
})
export class AgGridEditButtonComponent implements ICellRendererAngularComp {

  bsModalRef?: BsModalRef;
  params: ICellRendererParams;
  value: string;
  gridApi: GridApi;
  gridData: any;

  constructor(private router: Router) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.gridApi = params.api;
  }

  openDetails() {
    this.router.navigateByUrl('billing/invoices/' + this.params.data.id);
  }

  refresh() {
    return false;
  }
}
