import { Component, Input, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslocoService } from '@ngneat/transloco';
import { AccountInfoModel } from 'src/app/security/models/account-info.model';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  @Input() userInformation: AccountInfoModel;

  collapsed: boolean = true;
  currentLanguage: string;

  constructor(
    private msalService: MsalService,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.translocoService.langChanges$.subscribe(lang => {
      this.currentLanguage = lang
    })
  }

  toggleNavbar() {
    this.collapsed = !this.collapsed;
  }

  logOut() {
    localStorage.removeItem('app_token');
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: '/billing/logout'
    })
  }

  setLanguage(language: string){
    this.translocoService.setActiveLang(language);
  }

}
