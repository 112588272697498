import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BillingGroup } from 'src/app/billing/models/billing-group/billing-group.model';
import { EmployeeProject } from 'src/app/billing/models/employee/employee-project.model';
import { Project } from 'src/app/billing/models/project/project.model';
import { BillingGroupService } from 'src/app/billing/services/billing-group.service';
import { ProjectService } from 'src/app/billing/services/project.service';
import { EmployeeService } from 'src/app/billing/services/employee.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-edit-customer-details-modal',
  templateUrl: 'edit-customer-details-modal.component.html',
  styleUrls: ['edit-customer-details-modal.component.scss']
})
export class EditCustomerDetailsModalComponent extends BaseComponent implements OnInit {

  @Output() confirmed = new EventEmitter<boolean>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;
  modalRef?: BsModalRef;
  form = new UntypedFormGroup({});
  projectEmployeeForm = new UntypedFormGroup({});
  project: Project;
  billingGroups: BillingGroup[];
  message = new UntypedFormControl('', Validators.required);
  submitted = false;
  prEmployees: EmployeeProject[];

  constructor(
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public toastr: ToastrService,
    public billingGroup: BillingGroupService,
    private projectService: ProjectService,
    private employeeService: EmployeeService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.loadEmployees();
    this.initFormGroup();
    this.loadBillingGroups();
  }

  loadBillingGroups() {
    this.billingGroup.getAllActiveBillingGroups().subscribe(x => {
      this.billingGroups = x;
    })
  }

  loadEmployees() {
    this.employeeService.getProjectEmployees(this.project.id).subscribe(x => {
      this.prEmployees = x;
      this.prEmployees.forEach(element => {
        this.initProjectEmployeeForm(element.id, element.employeeId, element.hourlyRate, element.employee.firstName + " " + element.employee.lastName);
      });
    });
  }

  initFormGroup() {
    this.form = this.fb.group({
      id: [this.project?.id, Validators.required],
      isActive: [this.project.isActive],
      hourlyRate: [this.project?.hourlyRate],
      billingGroupId: [this.project.billingGroupId],
      projectReference:[this.project.projectReference],
      projectEmployees: this.fb.array([])
    });
  }

  initProjectEmployeeForm(id:string, employeeId: string, hourlyRate: number, employeeName: string)
  {
    const projectEmployeeForm = this.fb.group({
      id:[id],
      projectId: [this.project?.id, Validators.required],
      employeeId:[employeeId],
      hourlyRate: [hourlyRate],
      employeeName: [employeeName]
    });

    this.projectEmployees.push(projectEmployeeForm);
  }
  
  onSubmit() {
    let projectData: Project = {... this.form.value}
    let id = this.form.get('id').value;
    if (this.form.valid) {
      this.projectService.updateProject(String(id), projectData).subscribe(x=> {
        this.toastr.success('Project successfully updated!');
        this.confirmed.emit(true);
        this.bsModalRef.hide();
      })
    }
    else {
      this.toastr.error('This form has validation errors!', '');
    }
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return 'is-invalid'
    }
    return '';
  }

  get hourlyRate(){
    return this.form.get('hourlyRate');
  }

  get projectEmployees(){
    return this.form.controls['projectEmployees'] as UntypedFormArray;
  }

}
