<ng-container>
    <div class="container-fluid mt-3">
        <div class="d-flex pb-2 ">
            <h2 class="pe-3">Import</h2>
        </div>
        <div class="row">
            <div class="col-sm-12 col-xl-6">
                <div class="alert alert-primary rounded-0 rounded-top" role="alert">
                    <span class="badge text-bg-secondary">1</span> Import Customers, Projects and Worktimes from «Premotec Time Tracker Tool», which has changed since the «Last Execution».
                    <br>
                    <span class="badge text-bg-secondary">2</span> Remove Worktimes, which were removed in the «Premotec Time Tracker Tool», for the given daterange.
                    <br/>
                    <br>
                    <div class="row text-center">
                        <div class="col-sm col-xl-3">
                            <b>Last Execution</b><br>
                            <span class="badge rounded-pill text-bg-success">{{ lastImportDates?.lastExecutionDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
                        </div>
                        with
                        <div class="col-sm col-xl-3">
                            <b class="ms-1">Selected Daterange</b><br>
                            <span class="badge rounded-pill text-bg-primary">{{ lastImportDates?.updatedWorktimesDaterangeStart | date: 'dd.MM.yyyy' }} - {{ lastImportDates?.updatedWorktimesDaterangeEnd| date: 'dd.MM.yyyy' }}</span>
                        </div>
                    </div>
                </div>
            
                <div class="row mb-2">
                    <div class="col-5">
                        <label for="startdate">Start date</label>
                        <pmt-datepicker
                            [(ngModel)]="startDate"
                            id="startDate"
                            [toolTipValue]="'showCalender' | transloco"
                            autocomplete="off">
                        </pmt-datepicker>
                    </div>
                    <div class="col-5">
                        <label for="endDate">End date</label>
                        <pmt-datepicker
                            [(ngModel)]="endDate"
                            id="startDate"
                            [toolTipValue]="'showCalender' | transloco"
                            autocomplete="off">
                        </pmt-datepicker>
                    </div> 
                    <div class="col-2 d-flex align-items-end">
                        <button [disabled]="loading" type="button" class="btn btn-primary w-100" (click)="importData()"><i class="fas fa-file-import"></i> Import</button>
                    </div>
                </div>

                <div *ngIf="loading" class="alert alert-warning rounded-0 rounded-top mt-3" role="alert">
                    <i class="fas fa-spinner fa-pulse fa-lg"></i>
                    Import process is working... please wait a few moments <br>
                </div>

            </div>
        </div>
    </div>  
</ng-container>