import { Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridBaseComponent } from "src/app/shared/ag-grid-shared/ag-grid-base.component";
import { Customer } from "src/app/billing/models/customer/customer.model";
import { CustomerService } from "src/app/billing/services/customer.service";
import { GridService } from "src/app/shared/services/grid.service";
import { AgGridCustomerActionButtonsComponent } from "./ag-grid-customer-action-buttons/ag-grid-customer-action-buttons.component";
import { CustomerGridColumnService } from "./customer-grid-column.service";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
    templateUrl: './customer-overview.component.html',
    styleUrls: ['./customer-overview.component.scss'],
    providers: [GridService]
})
export class CustomerOverviewComponent extends AgGridBaseComponent implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    modalRef?: BsModalRef;
    public customers: Customer[];
    public loading: boolean;
    public importing: boolean;
    public showInvisibleCustomers: boolean;
    public dataTemplate: any;

    form: UntypedFormGroup;
    showInactive: boolean;
    activeSwitch = new UntypedFormControl()

    public defaultColDef: ColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true
    };

    constructor(
        private gridService: GridService,
        private customerService: CustomerService,
        private customerGridColumnService: CustomerGridColumnService,
        private router: Router,
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            switch: [null, Validators.requiredTrue]
        })
        this.updateData();
        this.initGrid();
        this.activeSwitch.valueChanges
            .subscribe((res) => {
                this.showActiveOrAll(res);
            })
    }

    private async initGrid() {
        this.addFrameWorkComponent('editButton', AgGridCustomerActionButtonsComponent);
        const columnDefs = await this.customerGridColumnService.getColumns();
        this.gridOptions.columnDefs = columnDefs;
        this.gridOptions.suppressRowTransform = true;
        this.gridConfigReady = true;
    }

    public onGridReady(parameters: GridReadyEvent): void {
        super.onGridReady(parameters);
        this.showActiveOrAll(false);
        this.autoSize();
        this.fillGrid()
    }

    updateData() {
        this.loading = true;
        this.customerService.getCustomers().subscribe(
            x => {
                this.customers = x;
                this.loading = false;
            });
    }

    openDetails(id: string) {
        this.router.navigateByUrl('/customer/' + id);
    }

    toggleShowInvisibleCustomers() {
        this.showInvisibleCustomers = !this.showInvisibleCustomers;
    }

    showActiveOrAll(checked: boolean) {
        var isActiveFilterComponent = this.gridApi.getFilterInstance('visibleForBilling')!;
        if (checked) {
            isActiveFilterComponent.setModel({ type: 'equals', filter: '' });
        }
        else {
            isActiveFilterComponent.setModel({ type: 'equals', filter: 'true' });
        }
        this.gridApi.onFilterChanged();
    }

    autoSize() {
        const allColumnIds = [];
        this.gridOptions.columnApi.getAllColumns().forEach((column) => {
            if (!column['colDef'].width) {
                allColumnIds.push(column.getId());
            }
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
    }

    private fillGrid() {
        if (!this.customers || this.customers == null) { return; }

        this.gridService.processGrid(this.agGrid, this.customers, this.constructor.name);
        this.agGrid.api.sizeColumnsToFit();
    }

}