import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BillingGroup } from "src/app/billing/models/billing-group/billing-group.model";
import { Employee } from "src/app/billing/models/employee/employee.model";
import { ProjectUpdate } from "src/app/billing/models/project/project-update.model";
import { Project } from "src/app/billing/models/project/project.model";
import { BillingGroupService } from "src/app/billing/services/billing-group.service";
import { ProjectService } from "src/app/billing/services/project.service";
import { EmployeeService } from 'src/app/billing/services/employee.service';
import { EmployeeProject } from "src/app/billing/models/employee/employee-project.model";
import { BaseComponent } from "@premotec/ngx-essentials";
import { ActivatedRoute } from "@angular/router";

@Component({
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent extends BaseComponent implements OnInit {

    employees: Employee[];
    employeeProjects: EmployeeProject[];
    loading: boolean = true;
    customerId: string;
    projectId: string;
    project: Project;
    projectUpdate: ProjectUpdate;
    billingGroups: BillingGroup[];
    ok: string = 'c4e02d98-5dd0-46ea-968b-aef79413d069'

    constructor(
        private projectService: ProjectService,
        private billingGroupService: BillingGroupService,
        private route: ActivatedRoute,
        private employeeService: EmployeeService,
        private toastr: ToastrService) {
        super();

        this.route.params.subscribe(params => {
            if (params['customerId']) {
                this.customerId = params['customerId'];
            }
            if (params['projectId']) {
                this.projectId = params['projectId'];
            }
        });
    }

    ngOnInit(): void {
        this.loadProject();
        this.loadEmployees();
        this.loadBillingGroups();
    }

    updateProject() {
        this.projectUpdate = {
            projectReference: this.project.projectReference,
            hourlyRate: this.project.hourlyRate,
            billingGroupId: this.project.billingGroupId,
            projectEmployees: this.project.projectEmployees,
        };

        this.projectService.updateProject(this.project.id, this.projectUpdate).subscribe(x => {
            this.project.billingGroupId = x.billingGroupId;
            this.project.hourlyRate = x.hourlyRate;
            this.project.projectReference = x.projectReference;
            this.project.projectEmployees = x.projectEmployees;
            this.toastr.success('Project successfully updated!',);
        });
    }

    loadBillingGroups() {
        this.billingGroupService.getAllBillingGroups().subscribe(x => {
            this.billingGroups = x;
            this.loading = false;
        });
    }

    selectedBillingGroupChanged(id: string) {
        this.project.billingGroupId = id;
    }

    loadProject() {
        this.projectService.getProjectById(this.projectId).subscribe(x => {
            this.project = x;
        });
    }

    loadEmployees() {
        this.employeeService.getProjectEmployees(this.project.id).subscribe(x => {
          this.employeeProjects = x;
        })
      }

    projectLoaded(): boolean {
        return this.project != null;
    }
}